import FormFieldValues from "@/components/Form/Fields/FormFieldValues";
import FormStruct from "@/components/Form/FormStruct/FormStruct";
import FormValidators, {
  FV,
} from "@/components/Form/Validation/FormValidators";
import PDFViewer from "@/components/PDFViewer/PDFViewer";
import i18n from "@/i18n";
import BFDatefield from "@/modules/abstract-ui/forms/datefield/BFDatefield";
import BFInput from "@/modules/abstract-ui/forms/input/BFInput";
import BFRadioGroup from "@/modules/abstract-ui/forms/radio-group/BFRadioGroup";
import BfRadio from "@/modules/abstract-ui/forms/radio/BfRadio";
import BFSelect from "@/modules/abstract-ui/forms/select/BFSelect";
import FileUtils from "@/utils/FileUtils";
import classNames from "classnames";
import { useState } from "react";
import { Field } from "react-final-form";
import { getRentPaymentTimingOption } from "../../../CBTenantsConst";
import {
  AgreementOption,
  DepositPosition,
  GraduatedRent,
  IndexOption,
  PaymentPosition,
  RentalAgreementAutomatic,
} from "../../../TenantsInterfaces";
import CBRentalAgreementAutomaticForm, {
  CBRentalAgreementAutomaticFormButton,
} from "../form-components/CBRentalAgreementAutomaticForm";
import CBRentalAgreementDepositForm, {
  CBRentalAgreementDepositButton,
} from "../form-components/CBRentalAgreementDepositForm";
import CBRentalAgreementGraduatedRentForm, {
  CBRentalAgreementGraduatedRentFormButton,
} from "../form-components/CBRentalAgreementGraduatedRentForm";
import CBRentalAgreementIndexForm, {
  CBRentalAgreementIndexFormButton,
} from "../form-components/CBRentalAgreementIndexForm";
import CBRentalAgreementNoteForm, {
  CBRentalAgreementNoteButton,
} from "../form-components/CBRentalAgreementNoteForm";
import CBRentalAgreementOptionForm, {
  CBRentalAgreementOptionFormButton,
} from "../form-components/CBRentalAgreementOptionForm";
import CBRentalAgreementPaymentPositions from "../form-components/CBRentalAgreementPaymentPositions";
import { CBRentalAgreementFormWizardStep1Value } from "./CBRentalAgreementFormWizardStep1UploadContract";
import { CBRentalAgreementFormWizardStep2Value } from "./CBRentalAgreementFormWizardStep2ContractPartners";
import { CBRentalAgreementFormWizardStep3Value } from "./CBRentalAgreementFormWizardStep3RentalUnits";
import "./CBRentalAgreementFormWizardStep4ContractData.scss";

export type CBRentalAgreementFormWizardStep4Value = {
  contractType: "business" | "private";
  id: string;
  displayName: string;
  moveIn: Date;
  agreementExpiration?: Date;
  moveOut?: Date;
  taxable: "taxable" | "notTaxable";
  paymentPositions: PaymentPosition[];
  note: null | string;
  deposit: null | DepositPosition[];
  index: null | IndexOption;
  option: null | AgreementOption[];
  graduatedRent: null | GraduatedRent[];
  automatic: null | RentalAgreementAutomatic;
};
interface CBRentalAgreementFormWizardStep4ContractDataProps {
  data: {
    step1: CBRentalAgreementFormWizardStep1Value;
    step2: CBRentalAgreementFormWizardStep2Value;
    step3: CBRentalAgreementFormWizardStep3Value;
  };
  onValueChange: (index: number, value: any) => void;
  initialValues?: CBRentalAgreementFormWizardStep4Value;
  onBack: () => void;
  onSuccess: (values: CBRentalAgreementFormWizardStep4Value) => Promise<void>;
}
const CBRentalAgreementFormWizardStep4ContractData = (
  props: CBRentalAgreementFormWizardStep4ContractDataProps
) => {
  const [fileUrl, setFileUrl] = useState(
    FileUtils.fileToObjectUrl(props.data?.step1?.rentalAgreement?.[0]?.file)
  );

  const objectId = props.data.step2.objectId;
  return (
    <FormStruct
      title={i18n.t(
        "cb:RentalAgreement.Form.Step4.title",
        "Vertragsdaten auswählen"
      )}
      description={i18n.t(
        "cb:RentalAgreement.Form.Step4.description",
        "Wählen Sie die Vertragsbestandteile aus."
      )}
      onSubmit={async (values) => {
        props.onSuccess(values);
      }}
      submitText={i18n.t("Global.Buttons.continue")}
      onAbort={(formProps) => {
        props.onValueChange(3, formProps.values);
        props.onBack();
      }}
      initialValues={props.initialValues}
      cancelText={i18n.t("Global.Buttons.back")}
      className={classNames(
        `cb-rental-agreement-form-wizard-step4-contract-data`
      )}
      renderRight={(form) => (
        <div
          className={`pdf-viewer`}
          style={{
            width: "40vw",
            maxWidth: 1000,
            padding: 10,
          }}
        >
          <PDFViewer
            border
            filename={props.data.step1?.rentalAgreement?.[0]?.file?.name}
            height={"80vh"}
            url={fileUrl}
          />
        </div>
      )}
      render={(form) => (
        <div>
          <div className={`__flex`}>
            <div className={`__field`}>
              <Field name="contractType" validate={FV.required()}>
                {({ input, meta }) => (
                  <BFRadioGroup
                    inline
                    value={input.value}
                    onChange={(_value, checked) => input.onChange(checked)}
                    {...FV.getValidation(meta)}
                  >
                    <BfRadio value={"business"}>
                      {i18n.t("cb:RentalAgreement.Form.business", "Gewerblich")}
                    </BfRadio>
                    <BfRadio value={"private"}>
                      {i18n.t("cb:RentalAgreement.Form.private", "Privat")}
                    </BfRadio>
                  </BFRadioGroup>
                )}
              </Field>
            </div>
          </div>
          <div className="__flex">
            <div className={`__field __flex-1`}>
              <Field
                name="id"
                validate={FormValidators.compose(FormValidators.required())}
              >
                {({ input, meta }) => (
                  <BFInput
                    label={`${i18n.t(
                      "cb:RentalAgreement.Form.Fields.ID",
                      "ID"
                    )}*`}
                    {...input}
                    {...FV.getValidation(meta)}
                    // placeholder={i18n.t(
                    //   "cb:RentalAgreement.Form.Fields.willBeGenerated",
                    //   "wird generiert"
                    // )}
                    // readonly
                  />
                )}
              </Field>
            </div>
            <div className={`__field __flex-3`}>
              <Field
                name="displayName"
                validate={FormValidators.compose(
                  FormValidators.required(),
                  FormValidators.max(150)
                )}
              >
                {({ input, meta }) => (
                  <BFInput
                    label={`${i18n.t(
                      "cb:RentalAgreement.Form.Fields.displayName",
                      "Anzeigename"
                    )}*`}
                    validation={
                      meta.error && meta.touched
                        ? { level: "error", message: meta.error }
                        : undefined
                    }
                    {...input}
                  />
                )}
              </Field>
            </div>
          </div>
          <div className="__flex">
            <div className={`__field __flex-1`}>
              <Field
                name="moveIn"
                validate={FormValidators.compose(FormValidators.required())}
              >
                {({ input, meta }) => (
                  <BFDatefield
                    label={`${i18n.t(
                      "cb:RentalAgreement.Form.Fields.moveIn",
                      "Mietbeginn"
                    )}*`}
                    {...FV.getValidation(meta)}
                    {...input}
                  />
                )}
              </Field>
            </div>
            <FormFieldValues names={["moveIn"]}>
              {([moveIn]) => (
                <div className={`__field __flex-1`}>
                  <Field
                    name="agreementExpiration"
                    validate={FormValidators.compose(
                      FormValidators.dateAfter(moveIn)
                    )}
                  >
                    {({ input, meta }) => (
                      <BFDatefield
                        label={i18n.t(
                          "cb:RentalAgreement.Form.Fields.agreementExpiration",
                          "Vertrag bis"
                        )}
                        {...FV.getValidation(meta)}
                        {...input}
                      />
                    )}
                  </Field>
                </div>
              )}
            </FormFieldValues>
            <FormFieldValues names={["moveIn"]}>
              {([moveIn]) => (
                <div className={`__field __flex-1`}>
                  <Field
                    name="moveOut"
                    validate={FormValidators.compose(
                      FormValidators.dateAfter(moveIn)
                    )}
                  >
                    {({ input, meta }) => (
                      <BFDatefield
                        label={i18n.t(
                          "cb:RentalAgreement.Form.Fields.moveOut",
                          "Auszug"
                        )}
                        {...FV.getValidation(meta)}
                        {...input}
                      />
                    )}
                  </Field>
                </div>
              )}
            </FormFieldValues>
          </div>
          <div className={`section-title __h3  margin-top-10`}>
            {i18n.t(
              "cb:RentalAgreement.Form.SectionTitle.PaymentData",
              "Zahldaten"
            )}
          </div>

          <div className={`__flex`}>
            <div className={`__field`}>
              <Field name="taxable">
                {({ input, meta }) => (
                  <BFRadioGroup
                    inline
                    value={input.value}
                    onChange={(_value, checked) => {
                      input.onChange(checked);

                      form.form.mutators.setValue("paymentPositions", []);
                      form.form.mutators.setValue("graduatedRent", null);
                    }}
                    {...FV.getValidation(meta)}
                  >
                    <BfRadio value={"taxable"}>
                      {i18n.t(
                        "cb:RentalAgreement.Form.taxable",
                        "Steuerpflichtig"
                      )}
                    </BfRadio>
                    <BfRadio value={"notTaxable"}>
                      {i18n.t(
                        "cb:RentalAgreement.Form.notTaxable",
                        "Nicht steuerpflichtig"
                      )}
                    </BfRadio>
                  </BFRadioGroup>
                )}
              </Field>
            </div>

            <div className={`__field margin-left-20`}>
              <Field
                name="rentPaymentTimingOptions"
                validate={FormValidators.required()}
              >
                {({ input, meta }) => (
                  <BFSelect
                    label={`${i18n.t(
                      "cb:RentalAgreement.Form.Fields.rentPaymentTimingOptions",
                      "Sollstellung"
                    )}`}
                    cleanable={false}
                    labelPosition={"left"}
                    ignoreDataSort
                    data={getRentPaymentTimingOption()}
                    {...FV.getValidation(meta)}
                    {...input}
                  />
                )}
              </Field>
            </div>
          </div>

          <div className={`__field`}>
            <CBRentalAgreementPaymentPositions
              objectId={objectId}
              fieldName="paymentPositions"
            />
          </div>

          <CBRentalAgreementNoteForm />
          <CBRentalAgreementDepositForm objectId={objectId} />
          <CBRentalAgreementIndexForm />
          <CBRentalAgreementGraduatedRentForm objectId={objectId} />
          <CBRentalAgreementAutomaticForm />
          <CBRentalAgreementOptionForm />

          <div className={`additional-actions`}>
            <CBRentalAgreementGraduatedRentFormButton />
            <CBRentalAgreementIndexFormButton />
            <CBRentalAgreementOptionFormButton />
            <CBRentalAgreementAutomaticFormButton />
            <CBRentalAgreementNoteButton />
            <CBRentalAgreementDepositButton />
          </div>
        </div>
      )}
    />
  );
};

export default CBRentalAgreementFormWizardStep4ContractData;
