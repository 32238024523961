import {
  ObjectKind,
  RentalUnitType,
} from "@/apps/tatar/objectsApp/types/objectKind.interface";
import ObjectKindStruct from "@/redux/actions/struct/implemented/ObjectKindStruct";
import OrgaStruct from "@/redux/actions/struct/implemented/OrgaStruct";
import UnitStruct from "@/redux/actions/struct/implemented/UnitStruct";
import moment from "moment";
import { useState } from "react";
import { useDispatch } from "react-redux";
import InfiniteTableFilter, {
  FilterComponentInitialState,
} from "../../../../../../../configurable/data/FilterComponent/InfiniteTableFilter";
import VirtualizedTableExportButton from "../../../../../../../configurable/data/VirtualizedTable/VirtualizedTableExportButton";
import TablePage from "../../../../../../../configurable/layouts/TablePageLayout/TablePage";
import DebugDataComponent from "../../../../../../../debug/DebugDataComponent";
import i18n from "../../../../../../../i18n";
import { AssetTypes } from "../../../../../../../model/AssetTypes";
import BFStatus from "../../../../../../../modules/abstract-ui/data/status/BFStatus";
import {
  LinkCell,
  renderCellValue,
} from "../../../../../../../modules/abstract-ui/data/table/TableUtils";
import { ColumnConfig } from "../../../../../../../modules/abstract-ui/data/virtualized-table/BFVirtualizedTable";
import BFSelect from "../../../../../../../modules/abstract-ui/forms/select/BFSelect";
import BFButton from "../../../../../../../modules/abstract-ui/general/Button/BFButton";
import { getDefaultCurrencyNumberFormat } from "../../../../../../../modules/export/export.model";
import { setFlexCacheData } from "../../../../../../../redux/actions/application/application-actions";
import { selectFlexConfig } from "../../../../../../../redux/actions/application/application-selectors";
import {
  useAggregationTableQuery,
  useTypedSelector,
} from "../../../../../../../redux/hooks";
import { AggregationStatisticQuerySelector } from "../../../../../../../redux/model";
import DataBus from "../../../../../../../services/DataBus";
import LanguageService from "../../../../../../../services/LanguageService";
import { useRestrictionsMatchQuery } from "../../../../../../../services/RestrictionService";
import { DataBusSubKeys } from "../../../../../../../utils/Constants";
import MQ from "../../../../../../../utils/MatchQueryUtils";
import StringUtils from "../../../../../../../utils/StringUtils";
import CBRentalUtils from "../../CBRentalUtils";
import {
  CB_RENTAL_VACANCY_LIST,
  getConfigRentalUnitTypeGroup,
} from "../../CBTenantsConst";
import {
  RentalVacancy,
  RentalVacancyAdvertisement,
} from "../../TenantsInterfaces";
import { CBRentalVacancyFilterOptions } from "./CBRentalVacancyFilterOptions";
import "./CBRentalVacancyList.scss";

export const RENTAL_VACANCY_STATIC_SELECTORS: AggregationStatisticQuerySelector[] =
  [
    {
      name: "general",
      op: [
        { key: "count", op: "count" },

        {
          key: "area",
          op: "sum",
          field: "expand.rentalUnit.data.area",
        },
        {
          key: "quantity",
          op: "sum",
          field: "expand.rentalUnit.data.quantity",
        },
        {
          key: "rentGross",
          op: "sum",
          field: "expand.rentalUnit.data.rentGross",
        },
        {
          key: "rentNet",
          op: "sum",
          field: "expand.rentalUnit.data.rentNet",
        },
        {
          key: "operatingCostGross",
          op: "sum",
          field: "expand.rentalUnit.data.operatingCostGross",
        },
        {
          key: "operatingCostNet",
          op: "sum",
          field: "expand.rentalUnit.data.operatingCostNet",
        },
      ],
      query: {},
    },
    //   {
    //     name: "booked",
    //     op: [{ key: "count", op: "count" }],
    //     query: {
    //       matchQuery: {
    //         type: "op",
    //         op: "eq",
    //         name: "data.booked",
    //         value: true,
    //       },
    //     },
    //   },
    //   {
    //     name: "payed",
    //     op: [{ key: "count", op: "count" }],
    //     query: {
    //       matchQuery: {
    //         type: "op",
    //         op: "eq",
    //         name: "data.payed",
    //         value: true,
    //       },4&
    //     },
    //   },
    //   {
    //     name: "isChecked",
    //     op: [{ key: "count", op: "count" }],
    //     query: {
    //       matchQuery: {
    //         type: "op",
    //         op: "eq",
    //         name: "data.payment.isChecked",
    //         value: true,
    //       },
    //     },
    //   },
  ];

const filterData = () => {
  return [
    {
      label: i18n.t("cb:RentalVacancy.FilterBy.all", "Alle Leerstände"),
      value: "all",
      query: null,
    },
    {
      label: i18n.t("cb:RentalVacancy.FilterBy.active", "Aktuelle Leerstände"),
      value: "active",
      query: MQ.and(
        MQ.lte("data.moveIn", moment().startOf("day")),
        MQ.or(
          MQ.gte("data.moveOut", moment().startOf("day")),
          MQ.isNull("data.moveOut")
        )
      ),
    },
    {
      label: i18n.t(
        "cb:RentalVacancy.FilterBy.inactive",
        "Ehemalige Leerstände"
      ),
      value: "inactive",
      query: MQ.and(MQ.lte("data.moveOut", moment().endOf("day"))),
    },
    {
      label: i18n.t(
        "cb:RentalVacancy.FilterBy.future",
        "Zukünftige Leerstände"
      ),
      value: "future",
      query: MQ.and(MQ.gt("data.moveIn", moment().startOf("day"))),
    },
  ];
};

const FlexKey = "cb_rentalVacancyList";
interface CBRentalVacancyListProps {
  kind: ObjectKind;
}
const CBRentalVacancyList = (props: CBRentalVacancyListProps) => {
  const restrictions = useRestrictionsMatchQuery();
  const dispatch = useDispatch();
  const [initialState, setInitialState] =
    useState<FilterComponentInitialState>(undefined);
  const aggregated = useAggregationTableQuery(
    CB_RENTAL_VACANCY_LIST,
    RENTAL_VACANCY_STATIC_SELECTORS,
    undefined,
    ["data.rentalUnit"]
  );
  const { filterValue } = useTypedSelector((state) =>
    selectFlexConfig(state, FlexKey)
  ) || { filterValue: "active" };

  const filterOptions = filterData();
  const additionalMatchQuery = filterOptions.find(
    (e) => e.value === filterValue
  )?.query;

  return (
    <TablePage
      layout={{
        className: "cb-rental-vacancy-list",
        cardHeader: (
          <div className={`vacancy-header`}>
            <div className={`margin-bottom-10 __h1 `}>
              {i18n.t("cb:RentalVacancy.title", "Leerstände")}
            </div>
            <div>
              <BFSelect
                onChange={(value) => {
                  dispatch(setFlexCacheData(FlexKey, "filterValue", value));
                }}
                cleanable={false}
                value={filterValue}
                data={filterOptions.map((e) => ({
                  label: e.label,
                  value: e.value,
                }))}
              />
            </div>
            <VirtualizedTableExportButton identifier={CB_RENTAL_VACANCY_LIST} />
          </div>
        ),
        pageHeader: (
          <>
            <InfiniteTableFilter
              initialState={initialState}
              identifier={CB_RENTAL_VACANCY_LIST}
              filterOptions={CBRentalVacancyFilterOptions(props.kind._id, [
                props.kind.data.type,
              ])}
            />
          </>
        ),
      }}
      table={{
        onRowDoubleClick: (asset: RentalVacancy, index) => {
          DataBus.emit(DataBusSubKeys.ROUTE, {
            append: true,
            route: `${asset._id}`,
          });
        },
        additionalMatchQuery: MQ.and(
          restrictions,
          MQ.eq("data.type", props.kind.data.type),
          additionalMatchQuery
        ),
        identifier: CB_RENTAL_VACANCY_LIST,
        dataUrl: `/api/asset/list/${AssetTypes.Rental.RentalVacancy}`,
        hover: true,
        asPost: true,
        onRowClick: (node: RentalVacancy) => {},
        params: { aggregated },
        selection: "single",
        expandKeys: ["data.rentalUnit"],
        exportOptions: {
          filename: `rental-vacancy_${moment().format("YYYY-MM-DD")}`,
        },
        columns: {
          "data.type": {
            label: i18n.t("Base.Unit"),
            sortable: true,
            flexWidth: 120,
            resizableOptions: {
              min: 40,
              max: 200,
            },
            resizable: true,
            render: (node: RentalVacancy, index, params) =>
              renderCellValue(
                node?.data?.type,
                "-",
                (value: string) => UnitStruct.getUnit(value)?.data.label
              ),
            renderFooter: (params) =>
              renderCellValue(
                `${i18n.t("cb:RentalVacancy.count", "Anz.")}: ${
                  params?.aggregated?.data?.["general"]?.count || "-"
                }`
              ),
            export: {
              width: 20,
              label: i18n.t("Base.Unit"),
              type: "string",
              selector: (node: RentalVacancy) =>
                LanguageService.translateLabel(
                  UnitStruct.getUnit(node?.data?.type)?.data.label
                ),
            },
          },
          "nested.entity": {
            label: i18n.t("Base.Entity"),
            // sortKey: "",
            flexWidth: 120,
            sortable: true,
            resizable: true,
            render: (node: RentalVacancy, index, params) =>
              renderCellValue(node?.nested?.entity, "-"),
            export: {
              width: 20,
              label: i18n.t("Base.Entity"),
              type: "string",
              selector: (node: RentalVacancy) => node?.nested?.entity,
            },
          },
          "nested.objectNumber": {
            label: i18n.t("Base.Object"),
            flexWidth: 200,
            resizable: true,
            sortable: true,
            render: (node: RentalVacancy, index, params) => (
              <LinkCell
                assetType={AssetTypes.Portfolio.Object}
                id={node?.data.objectId}
                text={`${node?.nested?.objectNumber} - ${node?.nested?.objectName}`}
                type={node?.data.type}
              />
            ),
            export: {
              width: 20,
              label: i18n.t("Base.Object"),
              type: "string",
              selector: (node: RentalVacancy) =>
                `${node?.nested?.objectNumber} - ${node?.nested?.objectName}`,
            },
          },
          ...RENTAL_VACANCY_FIELDS(),
        },
      }}
    />
  );
};

export default CBRentalVacancyList;

export const RENTAL_VACANCY_FIELDS = (withObject = false) =>
  ({
    ...(withObject
      ? {
          "data.objectId": {
            label: i18n.t("cb:RentalVacancy.object", "Objekt"),
            // sortKey: "",
            flexWidth: 120,
            sortable: true,
            resizable: true,
            render: (node: RentalVacancy, index, params) => (
              <>
                {renderCellValue(
                  node?.data?.objectId,
                  "-",
                  (id: string) =>
                    `${OrgaStruct.getObject(id)?.id} - ${
                      OrgaStruct.getObject(id)?.displayName
                    }`
                )}
              </>
            ),
            export: {
              width: 20,
              label: i18n.t("cb:RentalVacancy.object", "Objekt"),
              type: "string",
              selector: (node: RentalVacancy) =>
                node?.data?.objectId
                  ? `${OrgaStruct.getObject(node?.data?.objectId)?.id} - ${
                      OrgaStruct.getObject(node?.data?.objectId)?.displayName
                    }`
                  : "-",
            },
          },
        }
      : {}),
    "data.displayName": {
      label: i18n.t("cb:RentalVacancy.displayName", "Name"),
      // sortKey: "",
      flexWidth: 120,
      sortable: true,
      resizable: true,
      render: (node: RentalVacancy, index, params) => (
        <>
          <DebugDataComponent data={node} />
          {renderCellValue(node?.data?.displayName, "-")}
        </>
      ),
      export: {
        width: 20,
        label: i18n.t("cb:RentalVacancy.displayName", "Name"),
        type: "string",
        selector: (node: RentalVacancy) => node?.data?.displayName,
      },
    },

    status: {
      label: i18n.t("cb:RentalVacancy.status", "Status"),
      // sortKey: "",
      flexWidth: 140,
      sortable: false,
      resizable: true,
      render: (node: RentalVacancy, index, params) => {
        let status = CBRentalUtils.calculateVacancyStatus(node);
        return (
          <div style={{ maxWidth: "100%", overflow: "hidden" }}>
            <BFStatus label={status.label()} color={status.color} size={"sm"} />
          </div>
        );
      },
      export: {
        width: 20,
        label: i18n.t("cb:RentalVacancy.status", "Status"),
        type: "string",
        selector: (node: RentalVacancy) =>
          CBRentalUtils.calculateVacancyStatus(node).label(),
      },
    },
    tags: {
      label: i18n.t("cb:RentalVacancy.tags", "Tags"),
      // sortKey: "",
      flexWidth: 140,
      sortable: false,
      resizable: true,
      render: (node: RentalVacancy, index, params) => {
        const availableTags = UnitStruct.getTagConfig(
          node.data.type,
          AssetTypes.Rental.RentalVacancy
        );

        const tags =
          node.data.tags
            ?.map((e) => availableTags.find((a) => a.id === e))
            .filter((e) => e) || [];
        return (
          <div style={{ maxWidth: "100%", overflow: "hidden" }}>
            {tags.map((tag) => (
              <BFStatus
                key={tag.id}
                label={LanguageService.translateLabel(tag.displayName)}
                color={tag.color}
                size={"sm"}
              />
            ))}
          </div>
        );
      },
      export: {
        width: 20,
        label: i18n.t("cb:RentalVacancy.tags", "Tags"),
        type: "string",
        selector: (node: RentalVacancy) =>
          node.data.tags
            ?.map((e) =>
              UnitStruct.getTagConfig(
                node.data.type,
                AssetTypes.Rental.RentalVacancy
              ).find((a) => a.id === e)
            )
            .filter((e) => e)
            .map((e) => LanguageService.translateLabel(e.displayName))
            .join(", "),
      },
    },
    "data.moveIn": {
      label: i18n.t("cb:RentalVacancy.moveIn", "Von"),
      // sortKey: "",
      flexWidth: 120,
      sortable: true,
      resizable: true,
      render: (node: RentalVacancy, index, params) =>
        renderCellValue(node?.data?.moveIn, "-", (value: Date) =>
          moment(value).format(i18n.t("Formats.dateFormat"))
        ),
      export: {
        width: 20,
        label: i18n.t("cb:RentalVacancy.moveIn", "Von"),
        type: "date",
        selector: (node: RentalVacancy) => {
          return node?.data?.moveIn ? new Date(node?.data?.moveIn) : undefined;
        },
      },
    },
    "data.moveOut": {
      label: i18n.t("cb:RentalVacancy.moveOut", "Bis"),
      // sortKey: "",
      flexWidth: 120,
      sortable: true,
      resizable: true,
      render: (node: RentalVacancy, index, params) =>
        renderCellValue(node?.data?.moveOut, "-", (value: Date) =>
          moment(value).format(i18n.t("Formats.dateFormat"))
        ),
      export: {
        width: 20,
        label: i18n.t("cb:RentalVacancy.moveOut", "Bis"),
        type: "date",
        selector: (node: RentalVacancy) => {
          return node?.data?.moveOut
            ? new Date(node?.data?.moveOut)
            : undefined;
        },
      },
    },

    "nested.unitTypeGroup": {
      label: i18n.t("cb:RentalUnit.unitTypeGroup", "Nutzart"),
      // sortKey: "",
      flexWidth: 120,
      sortable: false,
      resizable: true,
      render: (node: RentalVacancy, index, params) => {
        const unit = ObjectKindStruct.getUnitTypeBy(
          node.expand?.rentalUnit?.data.unitType
        );
        if (!unit) return null;

        const status = getConfigRentalUnitTypeGroup(unit.group);
        if (status) {
          return (
            <div style={{ maxWidth: "100%", overflow: "hidden" }}>
              <BFStatus size="sm" color={status.color} label={status.label} />
            </div>
          );
        }
        return null;
      },
      export: {
        width: 20,
        label: i18n.t("cb:RentalUnit.unitTypeGroup", "Nutzart"),
        type: "string",
        selector: (node: RentalVacancy) => {
          const unit = ObjectKindStruct.getUnitTypeBy(
            node.expand?.rentalUnit?.data.unitType
          );
          if (!unit) return null;

          const status = getConfigRentalUnitTypeGroup(unit.group);
          if (status) {
            return status.label;
          }
          return null;
        },
      },
    },
    "expand.rentalUnit.data.unitType": {
      label: i18n.t("cb:RentalUnit.unitType", "Art"),
      // sortKey: "",
      flexWidth: 120,
      sortable: true,
      resizable: true,
      render: (node: RentalVacancy, index, params) =>
        renderCellValue(
          ObjectKindStruct.getUnitTypeBy(
            node.expand?.rentalUnit?.data.unitType
          ),
          "-",
          (value: RentalUnitType) =>
            LanguageService.translateLabel(value.displayName)
        ),
      export: {
        width: 20,
        label: i18n.t("cb:RentalUnit.unitType", "Art"),
        type: "string",
        selector: (node: RentalVacancy) => {
          const unit = ObjectKindStruct.getUnitTypeBy(
            node.expand?.rentalUnit?.data.unitType
          );
          if (!unit) return null;
          return LanguageService.translateLabel(unit.displayName);
        },
      },
    },
    "expand.rentalUnit.data.area": {
      label: i18n.t("cb:RentalAgreement.unitArea", "Fläche"),
      // sortKey: "",
      flexWidth: 120,
      sortable: true,
      resizable: true,
      render: (node: RentalVacancy, index, params) =>
        renderCellValue(
          node.expand?.rentalUnit?.data.area,
          "-",
          (value: number) => StringUtils.formatArea(value)
        ),
      renderFooter: (params) =>
        renderCellValue(
          params?.aggregated?.data?.["general"]?.area,
          "-",
          (value: number) => `${StringUtils.formatArea(value)}`
        ),
      export: {
        width: 20,
        label: i18n.t("cb:RentalAgreement.unitArea", "Fläche"),
        type: "number",
        selector: (node: RentalVacancy) => {
          return node.expand?.rentalUnit?.data.area;
        },
      },
    },
    "expand.rentalUnit.data.quantity": {
      label: i18n.t("cb:RentalAgreement.unitQuantity", "Stellplätze/Sonstiges"),
      // sortKey: "",
      flexWidth: 120,
      sortable: true,
      resizable: true,
      render: (node: RentalVacancy, index, params) =>
        renderCellValue(node.expand?.rentalUnit?.data.quantity, "-"),
      renderFooter: (params) =>
        renderCellValue(
          params?.aggregated?.data?.["general"]?.quantity,
          "-",
          (value: number) => `${value}`
        ),
      export: {
        width: 20,
        label: i18n.t(
          "cb:RentalAgreement.unitQuantity",
          "Stellplätze/Sonstiges"
        ),
        type: "number",
        selector: (node: RentalVacancy) => {
          return node.expand?.rentalUnit?.data.quantity;
        },
      },
    },

    "expand.rentalUnit.data.rentNet": {
      label: i18n.t("cb:RentalVacancy.rentNet", "Planmiete netto"),
      // sortKey: "",
      flexWidth: 120,
      sortable: true,
      resizable: true,
      render: (node: RentalVacancy, index, params) =>
        renderCellValue(
          node?.expand?.rentalUnit?.data.rentNet,
          "-",
          (value: number) => StringUtils.formatCurrency(value)
        ),
      renderFooter: (params) =>
        renderCellValue(
          params?.aggregated?.data?.["general"]?.rentNet,
          "-",
          (value: number) => `${StringUtils.formatCurrency(value)}`
        ),
      export: {
        width: 20,
        label: i18n.t("cb:RentalVacancy.rentNet", "Planmiete netto"),
        type: "number",
        totalFunction: "sum",
        style: {
          numFmt: getDefaultCurrencyNumberFormat(),
        },
        selector: (node: RentalVacancy) => {
          return node.expand?.rentalUnit?.data.rentNet;
        },
      },
    },
    "expand.rentalUnit.data.rentGross": {
      label: i18n.t("cb:RentalVacancy.rentGross", "Planmiete brutto"),
      // sortKey: "",
      flexWidth: 120,
      sortable: true,
      resizable: true,
      render: (node: RentalVacancy, index, params) =>
        renderCellValue(
          node?.expand?.rentalUnit?.data.rentGross,
          "-",
          (value: number) => StringUtils.formatCurrency(value)
        ),
      renderFooter: (params) =>
        renderCellValue(
          params?.aggregated?.data?.["general"]?.rentGross,
          "-",
          (value: number) => `${StringUtils.formatCurrency(value)}`
        ),
      export: {
        width: 20,
        label: i18n.t("cb:RentalVacancy.rentGross", "Planmiete brutto"),
        type: "number",
        totalFunction: "sum",
        style: {
          numFmt: getDefaultCurrencyNumberFormat(),
        },
        selector: (node: RentalVacancy) => {
          return node.expand?.rentalUnit?.data.rentGross;
        },
      },
    },
    "data.currentState": {
      label: i18n.t("cb:RentalVacancy.currentState", "Aktueller Status"),
      // sortKey: "",
      flexWidth: 250,
      sortable: true,
      resizable: true,
      render: (node: RentalVacancy, index, params) =>
        renderCellValue(node?.data?.currentState, "-"),
      export: {
        width: 20,
        label: i18n.t("cb:RentalVacancy.currentState", "Aktueller Status"),
        type: "string",
        selector: (node: RentalVacancy) => {
          return node?.data?.currentState;
        },
      },
    },
    countAdvertisements: {
      label: i18n.t("cb:RentalVacancy.advertisementsCount", "Inserate"),
      // sortKey: "",
      flexWidth: 120,
      sortable: true,
      resizable: true,
      render: (node: RentalVacancy, index, params) =>
        renderCellValue(
          node?.data?.advertisements || [],
          "-",
          (value: RentalVacancyAdvertisement[]) => (
            <div className={`cb-rental-vacancy-advertisements-cell`}>
              <BFButton
                className={`link-button`}
                appearance="link"
                noPadding
                size="xs"
                onClick={() => {
                  value
                    .filter((e) => e.status === "active")
                    .forEach((e) => window.open(e.link, "_blank"));
                }}
                disabled={
                  value.filter((e) => e.status === "active").length === 0
                }
              >
                {value.filter((e) => e.status === "active").length}{" "}
                {i18n.t("cb:RentalVacancy.advertisementsActive", "aktiv")}
              </BFButton>
              <span>/</span>
              <BFButton
                className={`link-button`}
                appearance="link"
                noPadding
                size="xs"
                onClick={() => {
                  value
                    .filter((e) => e.status === "closed")
                    .forEach((e) => window.open(e.link, "_blank"));
                }}
                disabled={
                  value.filter((e) => e.status === "closed").length === 0
                }
              >
                {value.filter((e) => e.status === "closed").length}{" "}
                {i18n.t("cb:RentalVacancy.advertisementsInactive", "inaktiv")}
              </BFButton>
            </div>
          )
        ),

      export: {
        width: 20,
        label: i18n.t("cb:RentalVacancy.advertisementsCount", "Inserate"),
        type: "string",
        selector: (node: RentalVacancy) => {
          return node?.data?.advertisements?.map((e) => e.link).join("\n");
        },
      },
    },
  } as { [key: string]: ColumnConfig });
