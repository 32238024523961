import { OAObject } from "@/apps/tatar/objectsApp/types/object.interface";
import { ObjectKind } from "@/apps/tatar/objectsApp/types/objectKind.interface";
import AssetLoader from "@/components/AssetLoader/AssetLoader";
import FormStruct from "@/components/Form/FormStruct/FormStruct";
import PDFViewer from "@/components/PDFViewer/PDFViewer";
import HTMLText from "@/components/WrappedText/HTMLText";
import WrappedText from "@/components/WrappedText/WrappedText";
import i18n from "@/i18n";
import { AssetTypes } from "@/model/AssetTypes";
import { Contact } from "@/model/db/Contact";
import BFFormSection from "@/modules/abstract-ui/data/form-section/BFFormSection";
import BFValueDisplay from "@/modules/abstract-ui/data/value-display/BFValueDisplay";
import BFDetailsButton from "@/modules/abstract-ui/general/Button/BFDetailsButton";
import ContactUtils from "@/modules/contacts-module/ContactUtils";
import OrgaStruct from "@/redux/actions/struct/implemented/OrgaStruct";
import LanguageService from "@/services/LanguageService";
import FileUtils from "@/utils/FileUtils";
import StringUtils from "@/utils/StringUtils";
import classNames from "classnames";
import _ from "lodash";
import moment from "moment";
import { useState } from "react";
import { RentalAgreementOptionBy } from "../../../CBRentalUtils";
import { PaymentPosition, RentalUnit } from "../../../TenantsInterfaces";
import ObjectStackingPlan from "../../stacking-plan/ObjectStackingPlan";
import {
  RentalUnitTableEntry,
  RentalUnitTableHeader,
} from "../CBRentalAgreementHelper";
import { CBRentalAgreementFormWizardStep1Value } from "./CBRentalAgreementFormWizardStep1UploadContract";
import { CBRentalAgreementFormWizardStep2Value } from "./CBRentalAgreementFormWizardStep2ContractPartners";
import { CBRentalAgreementFormWizardStep3Value } from "./CBRentalAgreementFormWizardStep3RentalUnits";
import { CBRentalAgreementFormWizardStep4Value } from "./CBRentalAgreementFormWizardStep4ContractData";
import "./CBRentalAgreementFormWizardStep5Overview.scss";

export type CBRentalAgreementFormWizardStep5Value = {};
interface CBRentalAgreementFormWizardStep5OverviewProps {
  kind: ObjectKind;
  data: {
    step1: CBRentalAgreementFormWizardStep1Value;
    step2: CBRentalAgreementFormWizardStep2Value;
    step3: CBRentalAgreementFormWizardStep3Value;
    step4: CBRentalAgreementFormWizardStep4Value;
  };
  initialValues?: CBRentalAgreementFormWizardStep5Value;
  onBack: () => void;
  onSuccess: (values: CBRentalAgreementFormWizardStep5Value) => Promise<void>;
}
const CBRentalAgreementFormWizardStep5Overview = (
  props: CBRentalAgreementFormWizardStep5OverviewProps
) => {
  const [fileUrl, setFileUrl] = useState(
    FileUtils.fileToObjectUrl(props.data?.step1?.rentalAgreement?.[0]?.file)
  );
  const objectId = props.data.step2.objectId;

  return (
    <FormStruct
      title={i18n.t("cb:RentalAgreement.Form.Step5.title", "Daten überprüfen")}
      description={i18n.t(
        "cb:RentalAgreement.Form.Step5.description",
        "Prüfen Sie alle Vertragsbestandteile"
      )}
      onSubmit={async (values) => {
        await props.onSuccess(values);
      }}
      submitText={i18n.t(
        "cb:RentalAgreement.Form.Step5.submit",
        "Mietvertrag erstellen"
      )}
      onAbort={props.onBack}
      initialValues={props.initialValues}
      cancelText={i18n.t("Global.Buttons.back")}
      className={classNames(`cb-rental-agreement-form-wizard-step5-overview`)}
      renderRight={(form) => (
        <div
          className={`pdf-viewer`}
          style={{
            width: "40vw",
            maxWidth: 1000,
            padding: 10,
          }}
        >
          <PDFViewer
            border
            filename={props.data.step1?.rentalAgreement?.[0]?.file?.name}
            height={"80vh"}
            url={fileUrl}
          />
        </div>
      )}
      render={(form) => (
        <div>
          <BFFormSection
            title={i18n.t(
              "cb:RentalAgreement.Form.Sections.contractor",
              "Vertragsgesellschaft"
            )}
            marginBottom={20}
            initialOpen
          >
            <div className={`__flex-wrap`}>
              <BFValueDisplay
                label={i18n.t(
                  "cb:RentalAgreement.Form.Fields.entity",
                  "Gesellschaft"
                )}
                value={props.data.step2.entity}
                formatter={(value) => OrgaStruct.getEntity(value)?.displayName}
              />
              <BFValueDisplay
                label={i18n.t(
                  "cb:RentalAgreement.Form.Fields.objectId",
                  "Objekt"
                )}
                value={props.data.step2.objectId}
                formatter={(value) => OrgaStruct.getObject(value)?.displayName}
              />
            </div>
          </BFFormSection>
          <BFFormSection
            title={i18n.t(
              "cb:RentalAgreement.Form.Sections.Beneficiary",
              "Vertragspartner"
            )}
            marginBottom={20}
            initialOpen
          >
            <AssetLoader
              assetType={AssetTypes.Contact}
              id={props.data.step2.tenant}
              inline
              render={(tenant: Contact) => {
                const contactOption = props.data.step2.contactOption;
                return (
                  <>
                    <div className={`__flex-wrap`}>
                      <BFValueDisplay
                        label={i18n.t("cb:Tenant.Labels.Name", "Mieter")}
                        value={
                          <BFDetailsButton
                            appearance="link"
                            noPadding
                            data={{
                              assetType: AssetTypes.Contact,
                              assetId: props.data.step2.tenant,
                              type: props.kind.data.type,
                              params: {
                                contactType: "TENANT",
                              },
                            }}
                          >
                            {tenant.data.displayName}
                          </BFDetailsButton>
                        }
                      />

                      <BFValueDisplay
                        label={i18n.t(
                          "cb:RentalAgreement.Labels.Contact.addressId",
                          "Kontaktadresse"
                        )}
                        value={contactOption?.addressId}
                        formatter={(value) =>
                          ContactUtils.getAdressSelectOptions(tenant)?.find(
                            (e) => e.value === value
                          )?.label
                        }
                      />

                      <BFValueDisplay
                        label={i18n.t(
                          "cb:RentalAgreement.Labels.Contact.contactPersonId",
                          "Kontaktperson"
                        )}
                        value={contactOption?.contactPersonId}
                        formatter={(value) =>
                          ContactUtils.getContactPersonSelectOptions(
                            tenant
                          )?.find((e) => e.value === value)?.label
                        }
                      />

                      <BFValueDisplay
                        label={i18n.t(
                          "cb:RentalAgreement.Labels.Contact.mail",
                          "Kontakt E-Mail"
                        )}
                        value={contactOption?.mail}
                        formatter={(value) =>
                          ContactUtils.getEmailSelectOptions(tenant)?.find(
                            (e) => e.value === value
                          )?.label
                        }
                      />

                      <BFValueDisplay
                        label={i18n.t(
                          "cb:RentalAgreement.Labels.Contact.phone",
                          "Kontakt Telefon"
                        )}
                        value={contactOption?.phone}
                        formatter={(value) =>
                          ContactUtils.getPhoneSelectOptions(tenant)?.find(
                            (e) => e.value === value
                          )?.label
                        }
                      />
                      <BFValueDisplay
                        label={i18n.t(
                          "cb:RentalAgreement.Labels.Contact.sms",
                          "Kontakt SMS"
                        )}
                        value={contactOption?.sms}
                        formatter={(value) =>
                          ContactUtils.getPhoneSelectOptions(tenant)?.find(
                            (e) => e.value === value
                          )?.label
                        }
                      />
                    </div>
                  </>
                );
              }}
            />
          </BFFormSection>

          <BFFormSection
            title={i18n.t(
              "cb:RentalAgreement.Form.Sections.RentalUnits",
              "Mieteinheiten"
            )}
            marginBottom={20}
            initialOpen
          >
            <>
              <div className={`stacking-plan`} style={{ height: 200 }}>
                <ObjectStackingPlan
                  objectId={objectId}
                  markedUnits={(props.data.step3.rentalUnits || []).map(
                    (e) => e.assetId
                  )}
                />
              </div>
              <div className={`units`}>
                <RentalUnitTableHeader />
                {(props.data.step3.rentalUnits || []).map((rentalUnit) => (
                  <AssetLoader
                    assetType={AssetTypes.Rental.RentalUnit}
                    id={rentalUnit.assetId}
                    render={(rentalUnit: RentalUnit) => (
                      <RentalUnitTableEntry rentalUnit={rentalUnit} asLink />
                    )}
                  />
                ))}
              </div>
            </>
          </BFFormSection>
          <BFFormSection
            title={i18n.t(
              "cb:RentalAgreement.Form.Sections.ContractData",
              "Vertragsdaten"
            )}
            marginBottom={20}
            initialOpen
          >
            <>
              <div className={`__flex-wrap `}>
                <BFValueDisplay
                  label={i18n.t(
                    "cb:RentalAgreement.Form.Fields.contractType",
                    "Vertragsart"
                  )}
                  value={props.data.step4.contractType}
                  formatter={(value) =>
                    value === "business"
                      ? i18n.t("cb:RentalAgreement.Form.business", "Gewerblich")
                      : i18n.t("cb:RentalAgreement.Form.private", "Privat")
                  }
                />
                <BFValueDisplay
                  label={i18n.t("cb:RentalAgreement.Form.Fields.ID", "ID")}
                  value={props.data.step4.contractType}
                />
                <BFValueDisplay
                  label={i18n.t(
                    "cb:RentalAgreement.Form.Fields.displayName",
                    "Anzeigename"
                  )}
                  value={props.data.step4.displayName}
                />
                <BFValueDisplay
                  label={i18n.t(
                    "cb:RentalAgreement.Form.Fields.moveIn",
                    "Mietbeginn"
                  )}
                  value={props.data.step4.moveIn}
                  formatter={StringUtils.formatDate}
                />
                <BFValueDisplay
                  label={i18n.t(
                    "cb:RentalAgreement.Form.Fields.agreementExpiration",
                    "Vertrag bis"
                  )}
                  value={props.data.step4.agreementExpiration}
                  formatter={StringUtils.formatDate}
                />
                <BFValueDisplay
                  label={i18n.t(
                    "cb:RentalAgreement.Form.Fields.moveOut",
                    "Auszug"
                  )}
                  value={props.data.step4.moveOut}
                  formatter={StringUtils.formatDate}
                />
              </div>
              <div className={`__flex-wrap margin-top-10`}>
                <PaymentPositionsData
                  objectId={props.data.step2.objectId}
                  paymentPositions={props.data.step4.paymentPositions}
                  taxable={props.data.step4.taxable === "taxable"}
                />
              </div>

              {props.data.step4.note && (
                <div className={`__flex-wrap margin-top-10`}>
                  <BFValueDisplay
                    label={i18n.t(
                      "cb:RentalAgreement.Form.Fields.note",
                      "Notiz"
                    )}
                    value={props.data.step4.note}
                    formatter={(value) => <WrappedText text={value} />}
                  />
                </div>
              )}

              {(props.data.step4.deposit || []).length > 0 && (
                <div className={`margin-top-10`}>
                  <div
                    className={`__flex section-title __h3  margin-top-10 margin-bottom-10`}
                  >
                    {i18n.t(
                      "cb:RentalAgreement.Form.Fields.deposit",
                      "Kaution"
                    )}
                  </div>

                  <AssetLoader
                    assetType={AssetTypes.Portfolio.Object}
                    id={props.data.step2.objectId}
                    render={(object: OAObject) => {
                      const depositPositions =
                        object.data.feature.immo?.accounting.deposit;
                      return (
                        <>
                          {props.data.step4.deposit.map((deposit) => (
                            <div className={`__flex-wrap`}>
                              <BFValueDisplay
                                label={i18n.t(
                                  "cb:RentalAgreement.Labels.DepositValue",
                                  "Kautionswert"
                                )}
                                value={deposit.depositValue}
                                formatter={(value) =>
                                  StringUtils.formatCurrency(value, true)
                                }
                              />
                              <BFValueDisplay
                                label={i18n.t(
                                  "cb:RentalAgreement.Labels.DepositType",
                                  "Kautionsart"
                                )}
                                value={
                                  depositPositions?.find(
                                    (e) => e.id === deposit.identifier
                                  )?.displayName
                                }
                                formatter={LanguageService.translateLabel}
                              />
                              <BFValueDisplay
                                label={i18n.t(
                                  "cb:RentalAgreement.Labels.DepositStart",
                                  "Kautionsbeginn"
                                )}
                                value={deposit.depositStart}
                                formatter={StringUtils.formatDate}
                              />
                              <BFValueDisplay
                                label={i18n.t(
                                  "cb:RentalAgreement.Labels.DepositStart",
                                  "Kautionsbeginn"
                                )}
                                value={deposit.depositStart}
                                formatter={StringUtils.formatDate}
                              />
                            </div>
                          ))}
                        </>
                      );
                    }}
                  />
                </div>
              )}

              {(props.data.step4.option || []).length > 0 && (
                <div className={`margin-top-10`}>
                  <div
                    className={`__flex section-title __h3  margin-top-10 margin-bottom-10`}
                  >
                    {i18n.t("cb:RentalAgreement.Form.Fields.option", "Option")}
                  </div>

                  {props.data.step4.option.map((option, index) => (
                    <div className={`option-entry`}>
                      <div className={`title`}>
                        {i18n.t(
                          "cb:RentalAgreement.Labels.Option.option",
                          "Option"
                        )}{" "}
                        {index + 1}
                      </div>

                      <div className={`__flex-wrap padding-bottom-10`}>
                        <BFValueDisplay
                          label={i18n.t(
                            "cb:RentalAgreement.Labels.Option.until",
                            "Option bis"
                          )}
                          value={option.optionUntil}
                          formatter={StringUtils.formatDate}
                        />
                        <BFValueDisplay
                          label={i18n.t(
                            "cb:RentalAgreement.Labels.Option.optionBy",
                            "Option von"
                          )}
                          value={option.optionBy}
                          formatter={(value) =>
                            RentalAgreementOptionBy().find(
                              (e) => e.value === value
                            )?.label
                          }
                        />
                        <BFValueDisplay
                          label={i18n.t(
                            "cb:RentalAgreement.Labels.Option.extendsByMonths",
                            "Verlängerung um"
                          )}
                          value={option.extendsByMonths}
                          formatter={(value) =>
                            `${value} ${i18n.t(
                              "Global.Labels.months",
                              "Monate"
                            )}`
                          }
                        />
                        <BFValueDisplay
                          label={i18n.t(
                            "cb:RentalAgreement.Labels.Option.optionsUsed",
                            "Option verwendet"
                          )}
                          value={StringUtils.formatBoolean(option.optionUsed)}
                        />
                      </div>

                      <div className={`__flex`}>
                        <BFValueDisplay
                          label={i18n.t(
                            "cb:RentalAgreement.Labels.Option.note",
                            "Notiz"
                          )}
                          value={option.note}
                          formatter={(value) => <WrappedText text={value} />}
                        />
                      </div>
                    </div>
                  ))}
                </div>
              )}

              {props.data.step4.automatic && (
                <div className={`margin-top-10`}>
                  <div
                    className={`__flex section-title __h3  margin-top-10 margin-bottom-10`}
                  >
                    {i18n.t(
                      "cb:RentalAgreement.Form.Fields.automatic",
                      "Automatik"
                    )}
                  </div>
                  <div className={`__flex-wrap`}>
                    <BFValueDisplay
                      label={i18n.t(
                        "cb:RentalAgreement.Labels.Automatic.monthBeforeContractEnd",
                        "Vor Auszug"
                      )}
                      value={props.data.step4.automatic.monthBeforeContractEnd}
                      formatter={(value) =>
                        `${value} ${i18n.t("Global.Labels.months", "Monate")}`
                      }
                    />
                    <BFValueDisplay
                      label={i18n.t(
                        "cb:RentalAgreement.Labels.Automatic.extendByMonths",
                        "Verlängern um"
                      )}
                      value={props.data.step4.automatic.extendByMonths}
                      formatter={(value) =>
                        `${value} ${i18n.t("Global.Labels.months", "Monate")}`
                      }
                    />
                  </div>
                </div>
              )}
              {props.data.step4.index && (
                <div className={`margin-top-10`}>
                  <div
                    className={`__flex section-title __h3  margin-top-10 margin-bottom-10`}
                  >
                    {i18n.t("cb:RentalAgreement.Form.Fields.index", "Index")}
                  </div>

                  <div className={`__flex-wrap padding-bottom-10`}>
                    <BFValueDisplay
                      label={i18n.t(
                        "cb:RentalAgreement.Labels.Index.type",
                        "Art"
                      )}
                      value={props.data.step4.index.type}
                      formatter={(value) =>
                        value === "default"
                          ? i18n.t(
                              "cb:RentalAgreement.Form.Index.defaultType",
                              "Normal"
                            )
                          : i18n.t(
                              "cb:RentalAgreement.Form.Index.yearlyType",
                              "Jährlich"
                            )
                      }
                    />
                    {props.data.step4.index.type === "default" && (
                      <BFValueDisplay
                        label={i18n.t(
                          "cb:RentalAgreement.Labels.Index.indexFactor",
                          "Erhöhung bei Index-Veränderung"
                        )}
                        value={props.data.step4.index.indexFactor}
                        formatter={(value) => StringUtils.formatPercent(value)}
                      />
                    )}
                    {props.data.step4.index.type === "yearly" && (
                      <BFValueDisplay
                        label={i18n.t(
                          "cb:RentalAgreement.Labels.Index.indexMonth",
                          "Monat"
                        )}
                        value={props.data.step4.index.indexMonth}
                        formatter={(value) =>
                          moment().month(value).format("MMMM")
                        }
                      />
                    )}

                    <BFValueDisplay
                      label={i18n.t(
                        "cb:RentalAgreement.Labels.Index.lastPriceIndex",
                        "Letzter Indexierungsfaktor"
                      )}
                      value={props.data.step4.index.lastPriceIndex}
                    />

                    <BFValueDisplay
                      label={i18n.t(
                        "cb:RentalAgreement.Labels.Index.lastPriceIndexChange",
                        "Datum der Indexierung"
                      )}
                      value={props.data.step4.index.lastPriceIndexChange}
                      formatter={StringUtils.formatDate}
                    />
                    <BFValueDisplay
                      label={i18n.t(
                        "cb:RentalAgreement.Labels.Index.ignoreUntil",
                        "Indexierungssperre bis"
                      )}
                      value={props.data.step4.index.ignoreUntil}
                      formatter={StringUtils.formatDate}
                    />
                    <BFValueDisplay
                      label={i18n.t(
                        "cb:RentalAgreement.Labels.Index.backPayPossible",
                        "Rückzahlung möglich"
                      )}
                      value={props.data.step4.index.backPayPossible}
                      formatter={(value) => StringUtils.formatBoolean(value)}
                    />
                  </div>
                  <div className={`__flex-wrap padding-bottom-10`}>
                    <BFValueDisplay
                      label={i18n.t(
                        "cb:RentalAgreement.Labels.Index.paragraph",
                        "Absatz im Mietvertrag"
                      )}
                      value={props.data.step4.index.paragraph}
                    />

                    <BFValueDisplay
                      label={i18n.t(
                        "cb:RentalAgreement.Labels.Index.agreementLabel",
                        "Mietvertragsname"
                      )}
                      value={props.data.step4.index.agreementLabel}
                    />
                  </div>

                  <div className={`__flex`}>
                    <BFValueDisplay
                      label={i18n.t(
                        "cb:RentalAgreement.Labels.Index.note",
                        "Notiz"
                      )}
                      value={props.data.step4.index.note}
                      formatter={(value) => (
                        <HTMLText withBorder maxHeight={400} text={value} />
                      )}
                    />
                  </div>
                </div>
              )}
              {props.data.step4.graduatedRent && (
                <div className={`margin-top-10`}>
                  <div
                    className={`__flex section-title __h3  margin-top-10 margin-bottom-10`}
                  >
                    {i18n.t(
                      "cb:RentalAgreement.Form.Fields.graduatedRent",
                      "Staffelmiete"
                    )}
                  </div>

                  {props.data.step4.graduatedRent.map(
                    (graduatedRent, index) => (
                      <div>
                        <div className={` __h4 margin-top-10`}>
                          {i18n.t("Global.Labels.fromDate")}{" "}
                          {StringUtils.formatDate(graduatedRent.fromDate)}
                        </div>
                        <div className={`__flex-wrap`}>
                          <PaymentPositionsData
                            objectId={props.data.step2.objectId}
                            paymentPositions={graduatedRent.paymentPositions}
                            taxable={props.data.step4.taxable === "taxable"}
                          />
                        </div>
                      </div>
                    )
                  )}
                </div>
              )}
            </>
          </BFFormSection>
        </div>
      )}
    />
  );
};

export default CBRentalAgreementFormWizardStep5Overview;

const PaymentPositionsData = (props: {
  objectId: string;
  paymentPositions: PaymentPosition[];
  taxable: boolean;
}) => {
  return (
    <AssetLoader
      assetType={AssetTypes.Portfolio.Object}
      id={props.objectId}
      render={(object: OAObject) => {
        const debitPositionsRent =
          object.data.feature.immo.accounting.debitposition
            .filter((e) => e.kind === "rent")
            .map((e) => e.id);
        const debitPositionsOperatingCosts =
          object.data.feature.immo.accounting.debitposition
            .filter(
              (e) =>
                e.kind === "operatingCost" ||
                e.kind === "operatingCostAllInclusive"
            )
            .map((e) => e.id);
        return (
          <>
            <BFValueDisplay
              label={i18n.t(
                "cb:RentalAgreement.Form.Fields.rentNet",
                "Miete Netto"
              )}
              value={props.paymentPositions
                .filter((e) => debitPositionsRent.includes(e.id))
                .map((e) => e.net || 0)}
              formatter={(value) => StringUtils.formatCurrency(_.sum(value))}
            />
            {props.taxable && (
              <BFValueDisplay
                label={i18n.t(
                  "cb:RentalAgreement.Form.Fields.rentGross",
                  "Miete Brutto"
                )}
                value={props.paymentPositions
                  .filter((e) => debitPositionsRent.includes(e.id))
                  .map((e) => e.gross || 0)}
                formatter={(value) => StringUtils.formatCurrency(_.sum(value))}
              />
            )}
            <BFValueDisplay
              label={i18n.t(
                "cb:RentalAgreement.Form.Fields.operatingCostNet",
                "Nebenkosten Netto"
              )}
              value={props.paymentPositions
                .filter((e) => debitPositionsOperatingCosts.includes(e.id))
                .map((e) => e.net || 0)}
              formatter={(value) => StringUtils.formatCurrency(_.sum(value))}
            />
            {props.taxable && (
              <BFValueDisplay
                label={i18n.t(
                  "cb:RentalAgreement.Form.Fields.operatingCostGross",
                  "Nebenkosten Brutto"
                )}
                value={props.paymentPositions
                  .filter((e) => debitPositionsOperatingCosts.includes(e.id))
                  .map((e) => e.gross || 0)}
                formatter={(value) => StringUtils.formatCurrency(_.sum(value))}
              />
            )}
          </>
        );
      }}
    />
  );
};
