import { OAObject } from "@/apps/tatar/objectsApp/types/object.interface";
import AssetLoader from "@/components/AssetLoader/AssetLoader";
import FormStruct from "@/components/Form/FormStruct/FormStruct";
import ModalManager from "@/components/ModalComponent/ModalManager";
import { AssetTypes } from "@/model/AssetTypes";
import BFDatefield from "@/modules/abstract-ui/forms/datefield/BFDatefield";
import { CustomFieldEditType } from "@/modules/customfields/CustomField.interface";
import CFAttachment from "@/modules/customfields/fields/CFAttachment";
import LanguageService from "@/services/LanguageService";
import { useState } from "react";
import { Field } from "react-final-form";
import { FieldArray } from "react-final-form-arrays";
import FormFieldValues from "../../../../../../../../components/Form/Fields/FormFieldValues";
import FormValidators from "../../../../../../../../components/Form/Validation/FormValidators";
import i18n from "../../../../../../../../i18n";
import BFInput from "../../../../../../../../modules/abstract-ui/forms/input/BFInput";
import BFSelect from "../../../../../../../../modules/abstract-ui/forms/select/BFSelect";
import BFButton from "../../../../../../../../modules/abstract-ui/general/Button/BFButton";
import { isDefined } from "../../../../../../../../utils/Helpers";
import StringUtils from "../../../../../../../../utils/StringUtils";
import CBRentalService from "../../../CBRentalService";
import { RentalAgreement } from "../../../TenantsInterfaces";
import "./CBRentalAgreementDepositForm.scss";

interface CBRentalAgreementDepositFormProps {
  force?: boolean;
  witboutDocuments?: boolean;
  objectId: string;
}
const CBRentalAgreementDepositForm = (
  props: CBRentalAgreementDepositFormProps
) => {
  const objectId = props.objectId;
  return (
    <div className={`cb-rental-agreement-deposit-form`}>
      <AssetLoader
        assetType={AssetTypes.Portfolio.Object}
        id={objectId}
        render={(object: OAObject) => (
          <Field name="deposits" defaultValue={null} allowNull>
            {({ input, meta }) =>
              !props.force && isDefined(input.value) ? (
                <FieldArray name="deposits">
                  {({ fields }) => (
                    <div className={`cb-rental-agreement-deposit-form`}>
                      <div
                        className={`__flex section-title __h3  margin-top-10 margin-bottom-10`}
                      >
                        <div className={`title`}>
                          {i18n.t(
                            "cb:RentalAgreement.Form.Fields.deposit",
                            "Kaution"
                          )}
                        </div>
                        {!props.force && (
                          <BFButton
                            onClick={() => input.onChange(null)}
                            appearance="link"
                            size="xs"
                            noPadding
                            className={`margin-left-10`}
                          >
                            {i18n.t("Global.Buttons.remove")}
                          </BFButton>
                        )}
                      </div>

                      {fields.map((name, index) => (
                        <div className={`deposit-entry`}>
                          <div className={`headline`}>
                            <BFButton
                              appearance="link"
                              size="xs"
                              onClick={() => {
                                if (fields.length > 1) {
                                  fields.remove(index);
                                } else {
                                  input.onChange(null);
                                }
                              }}
                            >
                              {i18n.t("Global.Buttons.remove")}
                            </BFButton>
                          </div>
                          <div className={`form`}>
                            <div className={`__flex`}>
                              <div className={`__field flex-1`}>
                                <Field
                                  name={`${name}.id`}
                                  validate={FormValidators.compose(
                                    FormValidators.required()
                                  )}
                                >
                                  {({ input, meta }) => (
                                    <BFSelect
                                      label={`${i18n.t(
                                        "cb:RentalAgreement.Form.Fields.depositType",
                                        "Kautionstyp"
                                      )}*`}
                                      data={object.data.feature?.immo?.accounting?.deposit.map(
                                        (d) => ({
                                          value: d.id,
                                          label: LanguageService.translateLabel(
                                            d.displayName
                                          ),
                                        })
                                      )}
                                      {...FormValidators.getValidation(meta)}
                                      {...input}
                                    />
                                  )}
                                </Field>
                              </div>
                              <div className={`__field flex-1`}>
                                <Field
                                  name={`${name}.depositValue`}
                                  validate={FormValidators.compose(
                                    FormValidators.required(),
                                    FormValidators.min(0)
                                  )}
                                >
                                  {({ input, meta }) => (
                                    <BFInput
                                      type="priceInput"
                                      prefix={StringUtils.getCurrencySymbol()}
                                      label={`${i18n.t(
                                        "cb:RentalAgreement.Form.Fields.depositValue",
                                        "Wert"
                                      )}*`}
                                      {...FormValidators.getValidation(meta)}
                                      {...input}
                                    />
                                  )}
                                </Field>
                              </div>
                              <div className={`__field flex-1`}>
                                <Field
                                  name={`${name}.depositStart`}
                                  validate={FormValidators.compose(
                                    FormValidators.required()
                                  )}
                                >
                                  {({ input, meta }) => (
                                    <BFDatefield
                                      label={`${i18n.t(
                                        "cb:RentalAgreement.Form.Fields.depositStart",
                                        "Kautionsbeginn"
                                      )}*`}
                                      {...FormValidators.getValidation(meta)}
                                      {...input}
                                    />
                                  )}
                                </Field>
                              </div>
                            </div>

                            {props.witboutDocuments !== true && (
                              <Field name={`${name}.document`}>
                                {(field) => (
                                  <div
                                    style={{ maxWidth: 400 }}
                                    className={`margin-bottom-20`}
                                  >
                                    <CFAttachment
                                      field={field}
                                      assetType={
                                        AssetTypes.Rental.RentalAgreement
                                      }
                                      data={{
                                        displayName: i18n.t(
                                          "cb:RentalAgreement.Form.DepositDocument",
                                          "Kautionsdokumente"
                                        ),
                                        fieldType:
                                          CustomFieldEditType.attachment,
                                        id: "attachments",
                                        validation: [],
                                      }}
                                    />
                                  </div>
                                )}
                              </Field>
                            )}

                            <div className={`__field flex-1`}>
                              <Field
                                name={`${name}.comment`}
                                validate={FormValidators.compose(
                                  FormValidators.max(400)
                                )}
                              >
                                {({ input, meta }) => (
                                  <BFInput
                                    type="textarea"
                                    label={`${i18n.t(
                                      "cb:RentalAgreement.Form.Fields.comment",
                                      "Notiz"
                                    )}`}
                                    {...FormValidators.getValidation(meta)}
                                    {...input}
                                  />
                                )}
                              </Field>
                            </div>
                          </div>
                        </div>
                      ))}
                      <div className={`__flex __justify-center`}>
                        <BFButton
                          noPadding
                          onClick={() => fields.push({})}
                          appearance="link"
                          size="xs"
                        >
                          {i18n.t(
                            "cb:RentalAgreement.Form.Fields.addDepositEntry",
                            "Weitere Kaution hinzufügen"
                          )}
                        </BFButton>
                      </div>
                    </div>
                  )}
                </FieldArray>
              ) : null
            }
          </Field>
        )}
      />
    </div>
  );
};

export default CBRentalAgreementDepositForm;

export const CBRentalAgreementDepositButton = () => {
  return (
    <FormFieldValues names={["objectId", "paymentPositions"]}>
      {([objectId, paymentPositions]) => (
        <Field name="deposits" allowNull defaultValue={null}>
          {({ input, meta }) => {
            if (!isDefined(input.value)) {
              return (
                <BFButton
                  type="button"
                  appearance="outline"
                  size="xs"
                  onClick={() => input.onChange([{}])}
                  text={i18n.t(
                    "cb:RentalAgreement.Form.Buttons.addDeposit",
                    "Kaution hinzufügen"
                  )}
                />
              );
            } else {
              return null;
            }
          }}
        </Field>
      )}
    </FormFieldValues>
  );
};

export const CBRentalAgreementDepositUpdateForm = (props: {
  rentalAgreement: RentalAgreement;
  onClose: () => void;
}) => {
  const [initialValues] = useState({
    deposit: props.rentalAgreement.data.deposit,
  });
  return (
    <FormStruct
      onSubmit={async (values) => {
        await CBRentalService.submitRentalAgreementDeposit(
          props.rentalAgreement._id,
          values
        );
        props.onClose();
      }}
      initialValues={initialValues}
      onAbort={props.onClose}
      title={i18n.t(
        i18n.t("cb:RentalAgreement.Form.SectionTitle.Depost", "Kaution")
      )}
      submitText={i18n.t("Global.Buttons.save")}
      additionalActions={
        <BFButton
          appearance="outline"
          onClick={async () => {
            ModalManager.confirm({
              title: i18n.t(
                "cb:RentalAgreement.Form.Delete.Deposit.title",
                "Kaution entfernen"
              ),
              message: i18n.t(
                "cb:RentalAgreement.Form.Delete.Deposit.description",
                "Wollen Sie die Kaution entfernen?"
              ),
              onConfirm: () => {
                CBRentalService.removeRentalAgreementDeposit(
                  props.rentalAgreement._id
                ).then(() => {
                  props.onClose();
                });
              },
            });
          }}
        >
          {i18n.t("Global.Buttons.delete")}
        </BFButton>
      }
      render={(formProps) => (
        <>
          <CBRentalAgreementDepositForm
            objectId={props.rentalAgreement.data.objectId}
            force
          />
        </>
      )}
    />
  );
};
