import { ObjectKind } from "@/apps/tatar/objectsApp/types/objectKind.interface";
import FormFieldValues from "@/components/Form/Fields/FormFieldValues";
import FormStruct from "@/components/Form/FormStruct/FormStruct";
import FormValidators, {
  FV,
} from "@/components/Form/Validation/FormValidators";
import PDFViewer from "@/components/PDFViewer/PDFViewer";
import i18n from "@/i18n";
import BFFormSection from "@/modules/abstract-ui/data/form-section/BFFormSection";
import BFSelect from "@/modules/abstract-ui/forms/select/BFSelect";
import ContactSelect from "@/modules/contacts-module/ContactSelect";
import OrgaStruct from "@/redux/actions/struct/implemented/OrgaStruct";
import FileUtils from "@/utils/FileUtils";
import classNames from "classnames";
import { useState } from "react";
import { Field } from "react-final-form";
import CBRentalAgreementContactForm from "../form-components/CBRentalAgreementContactForm";
import { CBRentalAgreementFormWizardStep1Value } from "./CBRentalAgreementFormWizardStep1UploadContract";
// import "./CBRentalAgreementFormWizardStep2ContractPartners.scss";

export type CBRentalAgreementFormWizardStep2Value = {
  entity: string;
  objectId: string;
  tenant: string;
  contactOption: {
    addressId: string;
    contactPersonId: string | null;
    mail: string | null;
    phone: string | null;
    sms: string | null;
  };
};
interface CBRentalAgreementFormWizardStep2ContractPartnersProps {
  kind: ObjectKind;
  data: {
    step1: CBRentalAgreementFormWizardStep1Value;
  };
  initialValues?: CBRentalAgreementFormWizardStep2Value;
  onValueChange: (index: number, value: any) => void;
  onBack: () => void;
  onSuccess: (values: CBRentalAgreementFormWizardStep2Value) => Promise<void>;
}
const CBRentalAgreementFormWizardStep2ContractPartners = (
  props: CBRentalAgreementFormWizardStep2ContractPartnersProps
) => {
  const [fileUrl, setFileUrl] = useState(
    FileUtils.fileToObjectUrl(props.data?.step1?.rentalAgreement?.[0]?.file)
  );
  return (
    <FormStruct
      title={i18n.t(
        "cb:RentalAgreement.Form.Step2.title",
        "Vertragspartner auswählen"
      )}
      description={i18n.t(
        "cb:RentalAgreement.Form.Step2.description",
        "Wählen Sie die Vertragspartner aus, die mit dem Mietvertrag verbunden sind."
      )}
      onSubmit={async (values) => {
        props.onSuccess(values);
      }}
      submitText={i18n.t("Global.Buttons.continue")}
      onAbort={(formProps) => {
        props.onValueChange(1, formProps.values);
        props.onBack();
      }}
      initialValues={props.initialValues}
      cancelText={i18n.t("Global.Buttons.back")}
      className={classNames(
        `cb-rental-agreement-form-wizard-step2-contract-partners`
      )}
      renderRight={(form) => (
        <div
          className={`pdf-viewer`}
          style={{
            width: "40vw",
            maxWidth: 1000,
            padding: 10,
          }}
        >
          <PDFViewer
            border
            filename={props.data.step1?.rentalAgreement?.[0]?.file?.name}
            height={"80vh"}
            url={fileUrl}
          />
        </div>
      )}
      render={(form) => (
        <div>
          <BFFormSection
            initialOpen
            disableCollapse
            marginBottom={20}
            title={i18n.t(
              "cb:RentalAgreement.Form.Sections.contractor",
              "Vertragsgesellschaft"
            )}
          >
            <div>
              <div className={`__field`}>
                <Field
                  name="entity"
                  validate={FormValidators.compose(FormValidators.required())}
                >
                  {({ input, meta }) => (
                    <BFSelect
                      {...input}
                      label={`${i18n.t(
                        "cb:RentalAgreement.Form.Fields.entity",
                        "Gesellschaft"
                      )}*`}
                      {...FV.getValidation(meta)}
                      data={OrgaStruct.getEntitySelectOptions(
                        props.kind.data.type
                      )}
                      onChange={(value) => {
                        input.onChange(value);
                        form.form.mutators.setValue("objectId", null);
                        // todo change to update form result of wizard step 3
                        form.form.mutators.setValue("rentalUnits", []);
                      }}
                    />
                  )}
                </Field>
              </div>

              <div className={`__field`}>
                <FormFieldValues names={["entity"]}>
                  {([entity]) => (
                    <Field
                      name="objectId"
                      validate={FormValidators.compose(
                        FormValidators.required()
                      )}
                    >
                      {({ input, meta }) => (
                        <BFSelect
                          {...input}
                          disabled={!entity}
                          label={`${i18n.t(
                            "cb:RentalAgreement.Form.Fields.objectId",
                            "Objekt"
                          )}*`}
                          {...FV.getValidation(meta)}
                          data={
                            entity
                              ? OrgaStruct.getObjectSelectOptions(entity)
                              : []
                          }
                          onChange={(value) => {
                            input.onChange(value);
                            // todo change to update form result of wizard step 3
                            form.form.mutators.setValue("rentalUnits", []);
                          }}
                        />
                      )}
                    </Field>
                  )}
                </FormFieldValues>
              </div>
            </div>
          </BFFormSection>
          <BFFormSection
            initialOpen
            title={i18n.t(
              "cb:RentalAgreement.Form.Sections.Beneficiary",
              "Vertragspartner"
            )}
          >
            <div>
              <Field name="tenant">
                {({ input, meta }) => (
                  <>
                    <div className={`__field`}>
                      <ContactSelect
                        businessUnits={[props.kind.data.type]}
                        contactTypes={["TENANT"]}
                        onChange={(value) => {
                          setTimeout(() => {
                            input.onChange(value);
                          });
                          if (value !== input.value) {
                            form.form.mutators.setValue("contactOption", {});
                          }
                        }}
                        value={input.value}
                        {...FormValidators.getValidation(meta)}
                        placeholder={i18n.t(
                          "cb:RentalAgreement.Form.Fields.tenantPlaceholder",
                          "Vertragspartner auswählen"
                        )}
                        addText={i18n.t(
                          "cb:RentalAgreement.Form.Fields.createTenant",
                          "Vertragspartner erstellen"
                        )}
                        initialCreateObj={
                          {
                            data: {
                              // ...initialData,
                              type: props.kind.data.type,
                              contactType: ["TENANT"],
                            },
                          } as any
                        }
                        {...FormValidators.getValidation(meta)}
                      />
                    </div>
                    {input.value && (
                      <div>
                        <CBRentalAgreementContactForm contactId={input.value} />
                      </div>
                    )}
                  </>
                )}
              </Field>
            </div>
          </BFFormSection>
        </div>
      )}
    />
  );
};

export default CBRentalAgreementFormWizardStep2ContractPartners;
