import FormStruct from "@/components/Form/FormStruct/FormStruct";
import { FV } from "@/components/Form/Validation/FormValidators";
import ModalManager from "@/components/ModalComponent/ModalManager";
import i18n from "@/i18n";
import BFCheckbox from "@/modules/abstract-ui/forms/checkbox/BFCheckbox";
import BFChooserSelect from "@/modules/abstract-ui/forms/chooser/BFChooserSelect";
import BFDatefield from "@/modules/abstract-ui/forms/datefield/BFDatefield";
import BFInput from "@/modules/abstract-ui/forms/input/BFInput";
import BFButton from "@/modules/abstract-ui/general/Button/BFButton";
import { isDefined } from "@/utils/Helpers";
import { useState } from "react";
import { Field } from "react-final-form";
import { FieldArray } from "react-final-form-arrays";
import CBRentalService from "../../../CBRentalService";
import { RentalAgreementOptionBy } from "../../../CBRentalUtils";
import { RentalAgreement } from "../../../TenantsInterfaces";
// import "./CBRentalAgreementOptionForm.scss";

interface CBRentalAgreementOptionFormProps {
  force?: boolean;
}
const CBRentalAgreementOptionForm = (
  props: CBRentalAgreementOptionFormProps
) => {
  return (
    <Field name="option" allowNull defaultValue={null}>
      {(option) => {
        if (!props.force && option.input.value === null) {
          return null;
        }
        return (
          <FieldArray name="option">
            {({ fields }) => (
              <div>
                {/* <div
                  className={`__flex section-title __h3  margin-top-10 margin-bottom-10`}
                >
                  <div className={`title`}>
                    {i18n.t(
                      "cb:RentalAgreement.Form.SectionTitle.Option",
                      "Option"
                    )}
                  </div>
                </div> */}
                {fields.map((name, index) => (
                  <div className={`option-form`}>
                    <div
                      className={`__flex section-title __h3  margin-top-10  margin-bottom-10`}
                    >
                      <div className={`title`}>
                        {i18n.t(
                          "cb:RentalAgreement.Form.SectionTitle.Option",
                          "Option"
                        )}{" "}
                        {index + 1}
                      </div>
                      <div className={`headline`}>
                        <BFButton
                          appearance="link"
                          size="xs"
                          onClick={() => {
                            if (fields.length > 1) {
                              fields.remove(index);
                            } else {
                              option.input.onChange(null);
                            }
                          }}
                        >
                          {i18n.t("Global.Buttons.remove")}
                        </BFButton>
                      </div>
                    </div>
                    <div className={`__flex`}>
                      <div className={`__field __flex-1`}>
                        <Field
                          name={`${name}.optionUntil`}
                          validate={FV.compose(FV.required())}
                        >
                          {({ input, meta }) => (
                            <BFDatefield
                              {...input}
                              {...FV.getValidation(meta)}
                              label={i18n.t(
                                "cb:RentalAgreement.Form.Option.optionUntil",
                                "Option bis zum"
                              )}
                            />
                          )}
                        </Field>
                      </div>
                      <div className={`__field __flex-1`}>
                        <Field
                          name={`${name}.optionBy`}
                          validate={FV.compose(FV.required())}
                        >
                          {({ input, meta }) => (
                            <BFChooserSelect
                              {...input}
                              {...FV.getValidation(meta)}
                              label={i18n.t(
                                "cb:RentalAgreement.Form.Option.optionBy",
                                "Option durch"
                              )}
                              hideSearch
                              data={RentalAgreementOptionBy()}
                            />
                          )}
                        </Field>
                      </div>
                      <div className={`__field __flex-1`}>
                        <Field
                          name={`${name}.extendsByMonths`}
                          validate={FV.compose(FV.required(), FV.min(1))}
                        >
                          {({ input, meta }) => (
                            <BFInput
                              {...input}
                              {...FV.getValidation(meta)}
                              type="number"
                              prefix={i18n.t("Global.Labels.months", "Monate")}
                              label={i18n.t(
                                "cb:RentalAgreement.Form.Option.extendsByMonths",
                                "Verlängerung um"
                              )}
                            />
                          )}
                        </Field>
                      </div>
                    </div>
                    <div className={`__flex`}>
                      <div className={`__field __flex-1`}>
                        <Field name={`${name}.optionUsed`}>
                          {({ input, meta }) => (
                            <BFCheckbox
                              checked={input.value}
                              onChange={(_, checked) => input.onChange(checked)}
                            >
                              {i18n.t(
                                "cb:RentalAgreement.Form.Option.optionUsed",
                                "Option gezogen"
                              )}
                            </BFCheckbox>
                          )}
                        </Field>
                      </div>
                    </div>
                    <div className={`__flex`}>
                      <div className={`__flex-1`}>
                        <Field name={`${name}.note`}>
                          {(paragraph) => (
                            <BFInput
                              type="textarea"
                              autoResize
                              {...paragraph.input}
                              {...FV.getValidation(paragraph.meta)}
                              label={i18n.t(
                                "cb:RentalAgreement.Form.Option.noteText",
                                "Notiz"
                              )}
                            />
                          )}
                        </Field>
                      </div>
                    </div>
                  </div>
                ))}
                <div className={`__flex __justify-center`}>
                  <BFButton
                    noPadding
                    onClick={() => fields.push(getInitial())}
                    appearance="link"
                    size="xs"
                  >
                    {i18n.t(
                      "cb:RentalAgreement.Form.Fields.addOption",
                      "Weitere Option hinzufügen"
                    )}
                  </BFButton>
                </div>
              </div>
            )}
          </FieldArray>
        );
      }}
    </Field>
  );
};

export default CBRentalAgreementOptionForm;

export const CBRentalAgreementOptionFormButton = () => {
  return (
    <Field name="option" allowNull defaultValue={null}>
      {({ input, meta }) => {
        if (!isDefined(input.value)) {
          return (
            <BFButton
              type="button"
              appearance="outline"
              size="xs"
              onClick={() => input.onChange([getInitial()])}
              text={i18n.t(
                "cb:RentalAgreement.Form.Buttons.addOption",
                "Option hinzufügen"
              )}
            />
          );
        } else {
          return null;
        }
      }}
    </Field>
  );
};
const getInitial = () => ({
  optionBy: "tenant",
  optionUsed: false,
  extendsByMonths: 36,
});
export const CBRentalAgreementOptionUpdateForm = (props: {
  rentalAgreement: RentalAgreement;
  onClose: () => void;
}) => {
  const [initialValues] = useState({
    option: props.rentalAgreement.data.option || [getInitial()],
  });
  return (
    <FormStruct
      onSubmit={async (values) => {
        await CBRentalService.submitRentalAgreementOptions(
          props.rentalAgreement._id,
          values.option
        );
        props.onClose();
      }}
      initialValues={initialValues}
      onAbort={props.onClose}
      title={i18n.t(
        i18n.t("cb:RentalAgreement.Form.SectionTitle.Option", "Option")
      )}
      submitText={i18n.t("Global.Buttons.save")}
      additionalActions={
        <BFButton
          appearance="outline"
          onClick={async () => {
            ModalManager.confirm({
              title: i18n.t(
                "cb:RentalAgreement.Form.Delete.Option.title",
                "Option entfernen"
              ),
              message: i18n.t(
                "cb:RentalAgreement.Form.Delete.Option.description",
                "Wollen Sie die Optionen entfernen?"
              ),
              onConfirm: () => {
                CBRentalService.removeRentalAgreementOptions(
                  props.rentalAgreement._id
                ).then(() => {
                  props.onClose();
                });
              },
            });
          }}
        >
          {i18n.t("Global.Buttons.delete")}
        </BFButton>
      }
      render={(formProps) => (
        <>
          <CBRentalAgreementOptionForm force />
        </>
      )}
    />
  );
};
