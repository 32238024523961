import FormStruct from "@/components/Form/FormStruct/FormStruct";
import { FV } from "@/components/Form/Validation/FormValidators";
import ModalManager from "@/components/ModalComponent/ModalManager";
import i18n from "@/i18n";
import BFInput from "@/modules/abstract-ui/forms/input/BFInput";
import BFButton from "@/modules/abstract-ui/general/Button/BFButton";
import { isDefined } from "@/utils/Helpers";
import { useState } from "react";
import { Field } from "react-final-form";
import CBRentalService from "../../../CBRentalService";
import { RentalAgreement } from "../../../TenantsInterfaces";
// import "./CBRentalAgreementAutomaticForm.scss";

interface CBRentalAgreementAutomaticFormProps {
  force?: boolean;
}
const CBRentalAgreementAutomaticForm = (
  props: CBRentalAgreementAutomaticFormProps
) => {
  return (
    <Field name="automatic" allowNull defaultValue={null}>
      {(automatic) => {
        if (!props.force && automatic.input.value === null) {
          return null;
        }
        return (
          <div className={`option-form`}>
            <div
              className={`__flex section-title __h3  margin-top-10  margin-bottom-10`}
            >
              <div className={`title`}>
                {i18n.t(
                  "cb:RentalAgreement.Form.SectionTitle.Automatic",
                  "Auszugsautomatik"
                )}
              </div>
              {!props.force && (
                <BFButton
                  onClick={() => automatic.input.onChange(null)}
                  appearance="link"
                  size="xs"
                  noPadding
                  className={`margin-left-10`}
                >
                  {i18n.t("Global.Buttons.remove")}
                </BFButton>
              )}
            </div>
            <div className={`__flex`}>
              <div className={`__flex-1`}>
                <Field
                  name="automatic.monthBeforeContractEnd"
                  validate={FV.compose(FV.required(), FV.min(1))}
                >
                  {(monthBeforeContractEnd) => (
                    <BFInput
                      type="number"
                      label={i18n.t(
                        "cb:RentalAgreement.Form.Fields.monthBeforeContractEnd",
                        "Anzahl Monate vor Auszug"
                      )}
                      prefix={i18n.t("Global.Labels.months", "Monate")}
                      {...monthBeforeContractEnd.input}
                      {...FV.getValidation(monthBeforeContractEnd.meta)}
                      step={1}
                    />
                  )}
                </Field>
              </div>
              <div className={`__flex-1`}>
                <Field
                  name="automatic.extendByMonths"
                  validate={FV.compose(FV.required(), FV.min(1))}
                >
                  {(extendByMonths) => (
                    <BFInput
                      type="number"
                      label={i18n.t(
                        "cb:RentalAgreement.Form.Fields.extendByMonths",
                        "Auszug verlängern um"
                      )}
                      prefix={i18n.t("Global.Labels.months", "Monate")}
                      {...extendByMonths.input}
                      {...FV.getValidation(extendByMonths.meta)}
                      step={1}
                    />
                  )}
                </Field>
              </div>
            </div>
          </div>
        );
      }}
    </Field>
  );
};

export default CBRentalAgreementAutomaticForm;
const getInitial = () => ({
  monthBeforeContractEnd: 6,
  extendByMonths: 12,
});
export const CBRentalAgreementAutomaticFormButton = () => {
  return (
    <Field name="automatic" allowNull defaultValue={null}>
      {({ input, meta }) => {
        if (!isDefined(input.value)) {
          return (
            <BFButton
              type="button"
              appearance="outline"
              size="xs"
              onClick={() => input.onChange(getInitial())}
              text={i18n.t(
                "cb:RentalAgreement.Form.Buttons.addAutomatic",
                "Auszugsautomatik hinzufügen"
              )}
            />
          );
        } else {
          return null;
        }
      }}
    </Field>
  );
};

export const CBRentalAgreementAutomaticUpdateForm = (props: {
  rentalAgreement: RentalAgreement;
  onClose: () => void;
}) => {
  const [initialValues] = useState({
    automatic: props.rentalAgreement.data.automatic || getInitial(),
  });
  return (
    <FormStruct
      onSubmit={async (values) => {
        await CBRentalService.submitRentalAgreementAutomatic(
          props.rentalAgreement._id,
          values.automatic
        );
        props.onClose();
      }}
      initialValues={initialValues}
      onAbort={props.onClose}
      title={i18n.t(
        i18n.t(
          "cb:RentalAgreement.Form.SectionTitle.Automatic",
          "Auszugsautomatik"
        )
      )}
      submitText={i18n.t("Global.Buttons.save")}
      additionalActions={
        <BFButton
          appearance="outline"
          onClick={async () => {
            ModalManager.confirm({
              title: i18n.t(
                "cb:RentalAgreement.Form.Delete.Automatic.title",
                "Auszugsautomatik entfernen"
              ),
              message: i18n.t(
                "cb:RentalAgreement.Form.Delete.Automatic.description",
                "Wollen Sie die Auszugsautomatik entfernen?"
              ),
              onConfirm: () => {
                CBRentalService.removeRentalAgreementAutomatic(
                  props.rentalAgreement._id
                ).then(() => {
                  props.onClose();
                });
              },
            });
          }}
        >
          {i18n.t("Global.Buttons.delete")}
        </BFButton>
      }
      render={(formProps) => (
        <>
          <CBRentalAgreementAutomaticForm force />
        </>
      )}
    />
  );
};
