import { OAObject } from "@/apps/tatar/objectsApp/types/object.interface";
import {
  ObjectKind,
  ObjectKindFeature_IMMO,
} from "@/apps/tatar/objectsApp/types/objectKind.interface";
import HTMLText from "@/components/WrappedText/HTMLText";
import WrappedText from "@/components/WrappedText/WrappedText";
import ListComponent from "@/configurable/components/ListComponent/ListComponent";
import Log from "@/debug/Log";
import BFValueDisplay from "@/modules/abstract-ui/data/value-display/BFValueDisplay";
import BFMessageBox from "@/modules/abstract-ui/general/Message/BFMessageBox";
import ContactUtils from "@/modules/contacts-module/ContactUtils";
import OrgaStruct from "@/redux/actions/struct/implemented/OrgaStruct";
import { hasNoValue, hasValue } from "@/utils/Helpers";
import MQ from "@/utils/MatchQueryUtils";
import moment from "moment";
import React from "react";
import AssetLoader from "../../../../../../../components/AssetLoader/AssetLoader";
import ModalManager from "../../../../../../../components/ModalComponent/ModalManager";
import PersistentSplitPane from "../../../../../../../configurable/data/SplitPane/PersistentSplitPane";
import DebugDataComponent from "../../../../../../../debug/DebugDataComponent";
import i18n from "../../../../../../../i18n";
import { AssetTypes } from "../../../../../../../model/AssetTypes";
import { Contact } from "../../../../../../../model/db/Contact";
import BFButton from "../../../../../../../modules/abstract-ui/general/Button/BFButton";
import BFDetailsButton from "../../../../../../../modules/abstract-ui/general/Button/BFDetailsButton";
import { DefaultIcons } from "../../../../../../../modules/abstract-ui/icon/DefaultIcons";
import { useAggregationStatisticQuery } from "../../../../../../../redux/hooks";
import DataBusDefaults from "../../../../../../../services/DataBusDefaults";
import GlobalActions from "../../../../../../../services/GlobalActions";
import LanguageService from "../../../../../../../services/LanguageService";
import StringUtils from "../../../../../../../utils/StringUtils";
import { RentalAgreementOptionBy } from "../../CBRentalUtils";
import {
  DepositPosition,
  EnrichtedRentalUnit,
  RentalAgreement,
  RentalAgreementTimePeriod,
  RentalUnit,
} from "../../TenantsInterfaces";
import ObjectStackingPlan from "../stacking-plan/ObjectStackingPlan";
import "./CBRentalAgreementDetailsPage.scss";
import {
  RentalUnitTableEntry,
  RentalUnitTableHeader,
} from "./CBRentalAgreementHelper";
import CBRentalAgreementRentChange from "./CBRentalAgreementRentChange";
import { CBRentalAgreementAutomaticUpdateForm } from "./form-components/CBRentalAgreementAutomaticForm";
import { CBRentalAgreementContactOptionUpdateForm } from "./form-components/CBRentalAgreementContactForm";
import { CBRentalAgreementIndexUpdateForm } from "./form-components/CBRentalAgreementIndexForm";
import { CBRentalAgreementOptionUpdateForm } from "./form-components/CBRentalAgreementOptionForm";

interface CBRentalAgreementDetailsProps {
  goBack?: () => void;
  rentalAgreement: RentalAgreement;
  hideBackButton?: boolean;
  hideStackingPlan?: boolean;
  hideActions?: boolean;
  kind: ObjectKind;
}
export const CBRentalAgreementDetailsPage = (
  props: CBRentalAgreementDetailsProps
) => {
  const chartId = React.useRef<string>(null);
  const comp = (
    <>
      <div className={`page-content`}>
        <div className={`header`}>
          {!props.hideBackButton && (
            <BFButton
              className={`back-button`}
              appearance={"clear-on-white"}
              icon={{ size: "xs", ...DefaultIcons.BACK }}
              onClick={props.goBack}
            />
          )}
          <div className={`title`}>
            {OrgaStruct.isImportedObject(
              props.rentalAgreement.data.objectId
            ) && (
              <div className={`margin-top-10`}>
                <BFMessageBox type="info" inline small>
                  {i18n.t(
                    "cb:RentalAgreement.ImportedObject",
                    "Importierter Mietvertrag aus Domus"
                  )}
                </BFMessageBox>
              </div>
            )}
            <div className={`id`}>
              {i18n.t("cb:RentalAgreement.DetailsTitle", "Mietvertrag")}{" "}
              {props.rentalAgreement.data.id}
              <DebugDataComponent data={props.rentalAgreement} />
            </div>
            <div className={`name`}>
              {props.rentalAgreement.data.displayName}
            </div>
            <div className={`object`}>
              <BFDetailsButton
                appearance="link"
                size="xs"
                data={{
                  assetType: AssetTypes.Portfolio.Object,
                  assetId: props.rentalAgreement.data.objectId,
                  type: props.rentalAgreement.data.type,
                }}
              >
                {OrgaStruct.getObject(props.rentalAgreement.data.objectId)?.id}{" "}
                -{" "}
                {
                  OrgaStruct.getObject(props.rentalAgreement.data.objectId)
                    ?.displayName
                }
              </BFDetailsButton>
            </div>
          </div>
        </div>
        <div className={`content`}>
          <div className={`__flex-wrap padding-bottom-20`}>
            <BFValueDisplay
              label={i18n.t("cb:RentalAgreement.Labels.moveIn", "Mietbeginn")}
              value={props.rentalAgreement.data.moveIn}
              formatter={StringUtils.formatDate}
            />
            <BFValueDisplay
              label={i18n.t(
                "cb:RentalAgreement.Form.Fields.agreementExpiration",
                "Vertrag bis"
              )}
              value={props.rentalAgreement.data.agreementExpiration}
              formatter={StringUtils.formatDate}
            />
            <BFValueDisplay
              label={i18n.t("cb:RentalAgreement.Form.Fields.moveOut", "Auszug")}
              value={props.rentalAgreement.data.moveOut}
              formatter={StringUtils.formatDate}
            />
          </div>

          <div className={`description-container`}>
            <div className={`label`}>
              {i18n.t("cb:RentalAgreement.Labels.note", "Notiz")}
            </div>
            <div className={`value`}>
              {props.rentalAgreement.data.note || "-"}
            </div>
          </div>

          <ContactData rentalAgreement={props.rentalAgreement} />

          <RentEntry
            kind={props.kind}
            hideActions={props.hideActions}
            rentalAgreement={props.rentalAgreement}
          />
          <DepositView
            objectId={props.rentalAgreement.data.objectId}
            deposit={props.rentalAgreement.data.deposit}
          />
          <GraduatedRentData
            kind={props.kind}
            rentalAgreement={props.rentalAgreement}
          />
          <RentalUnits rentalAgreement={props.rentalAgreement} />
          <IndexData rentalAgreement={props.rentalAgreement} />
          <OptionData rentalAgreement={props.rentalAgreement} />
          <AutomaticData rentalAgreement={props.rentalAgreement} />
        </div>
      </div>
    </>
  );
  return (
    <div className="cb-rental-agreement-details">
      {props.hideStackingPlan ? (
        comp
      ) : (
        <PersistentSplitPane
          identifier="cb-rental-agreement-details"
          split="horizontal"
          defaultSize={"50%"}
          maxSize={-200}
          minSize={150}
          primary="first"
          allowResize
          onSizeChange={() => {
            if (chartId.current) {
              DataBusDefaults.chartResized(chartId.current);
            }
          }}
        >
          {props.hideStackingPlan === true ? null : (
            <div className={`object-stacking-plan-wrapper`}>
              <ObjectStackingPlan
                onMounted={(ident, chart) => {
                  chartId.current = ident;
                }}
                objectId={props.rentalAgreement.data.objectId}
                markedUnits={props.rentalAgreement.data.rentalUnits}
                selectMode="single"
                onSelect={(selected: EnrichtedRentalUnit) => {
                  GlobalActions.openDetails(
                    selected.data.rentalStatus === "vacant"
                      ? AssetTypes.Rental.RentalUnit
                      : AssetTypes.Rental.RentalAgreement,
                    selected.data.rentalStatus === "vacant"
                      ? selected._id
                      : selected.data.agreement._id,
                    selected.data.type
                  );
                  //   DataBusDefaults.route({
                  //     route: `/rental-unit/${selected}`,
                  //     stayInApp: true,
                  //   });
                }}
              />
            </div>
          )}
          {comp}
        </PersistentSplitPane>
      )}
    </div>
  );
};

const RentalUnits = (props: { rentalAgreement: RentalAgreement }) => {
  return (
    <RentalAgreementSection
      title={i18n.t("cb:RentalAgreement.Labels.Rentalunits", "Mieteinheiten")}
    >
      <>
        <RentalUnitTableHeader />
        {props.rentalAgreement.data.rentalUnits.map((rentalUnit) => (
          <AssetLoader
            assetType={AssetTypes.Rental.RentalUnit}
            id={rentalUnit}
            render={(rentalUnit: RentalUnit) => (
              <RentalUnitTableEntry rentalUnit={rentalUnit} asLink />
            )}
          />
        ))}
      </>
    </RentalAgreementSection>
  );
};

const DepositView = (props: {
  deposit: DepositPosition[];
  objectId: string;
}) => {
  const deposit = props.deposit || [];

  return (
    <AssetLoader
      assetType={AssetTypes.Portfolio.Object}
      id={props.objectId}
      render={(object: OAObject) => {
        const depositPositions = object.data.feature.immo?.accounting.deposit;
        return (
          <RentalAgreementSection
            title={i18n.t("cb:RentalAgreement.Labels.Deposit", "Kaution")}
          >
            <div>
              {" "}
              {deposit.length === 0 && (
                <div className={`__empty`}>
                  {i18n.t(
                    "cb:RentalAgreement.Labels.NoDeposit",
                    "Keine Kaution hinterlegt"
                  )}
                </div>
              )}
              {deposit.map((deposit) => (
                <div className={`__flex-wrap`}>
                  <BFValueDisplay
                    label={i18n.t(
                      "cb:RentalAgreement.Labels.DepositValue",
                      "Kautionswert"
                    )}
                    value={deposit.depositValue}
                    formatter={(value) =>
                      StringUtils.formatCurrency(value, true)
                    }
                  />
                  <BFValueDisplay
                    label={i18n.t(
                      "cb:RentalAgreement.Labels.DepositType",
                      "Kautionsart"
                    )}
                    value={
                      depositPositions?.find((e) => e.id === deposit.identifier)
                        ?.displayName
                    }
                    formatter={LanguageService.translateLabel}
                  />
                  <BFValueDisplay
                    label={i18n.t(
                      "cb:RentalAgreement.Labels.DepositStart",
                      "Kautionsbeginn"
                    )}
                    value={deposit.depositStart}
                    formatter={StringUtils.formatDate}
                  />
                  <BFValueDisplay
                    label={i18n.t(
                      "cb:RentalAgreement.Labels.DepositStart",
                      "Kautionsbeginn"
                    )}
                    value={deposit.depositStart}
                    formatter={StringUtils.formatDate}
                  />
                </div>
              ))}
            </div>
          </RentalAgreementSection>
        );
      }}
    />
  );
};

const RentEntry = (props: {
  rentalAgreement: RentalAgreement;
  hideActions?: boolean;
  kind: ObjectKind;
}) => {
  const plannedRent = useAggregationStatisticQuery(
    AssetTypes.Rental.RentalUnit,
    {
      type: "and",
      query: [
        {
          type: "op",
          name: "_id",
          op: "in",
          value: props.rentalAgreement.data.rentalUnits,
        },
      ],
    },
    [
      {
        name: "general",
        op: [
          {
            key: "rentNet",
            op: "sum",
            field: "data.rentNet",
          },
          {
            key: "rentGross",
            op: "sum",
            field: "data.rentGross",
          },
          {
            key: "operatingCostNet",
            op: "sum",
            field: "data.operatingCostNet",
          },
          {
            key: "operatingCostGross",
            op: "sum",
            field: "data.operatingCostGross",
          },
        ],
        query: {},
      },
    ]
  );
  return (
    <RentalAgreementSection
      title={i18n.t("cb:RentalAgreement.Labels.Rent", "Miete")}
      onEdit={
        OrgaStruct.isImportedObject(props.rentalAgreement.data.objectId)
          ? undefined
          : () => {
              ModalManager.show({
                backdrop: "static",
                noPadding: true,
                size: "lg",
                content: (states, setStates, closeModal) => (
                  <CBRentalAgreementRentChange
                    onClose={closeModal}
                    kind={props.kind}
                    rentalAgreementId={props.rentalAgreement._id}
                  />
                ),
              });
            }
      }
    >
      <div className={`__flex-wrap`}>
        <BFValueDisplay
          label={i18n.t("cb:RentalAgreement.Labels.rentNet", "Miete netto")}
          value={props.rentalAgreement?.data?.rentNet}
          formatter={(value) => StringUtils.formatCurrency(value, true)}
        />
        {props.rentalAgreement.data.taxable && (
          <BFValueDisplay
            label={i18n.t(
              "cb:RentalAgreement.Labels.rentGross",
              "Miete brutto"
            )}
            value={
              <>
                <span className={`should`}>
                  {StringUtils.formatCurrency(
                    props.rentalAgreement.data.rentGross,
                    true
                  )}
                </span>{" "}
                {plannedRent.data ? (
                  <span className={`plan`}>
                    ({i18n.t("cb:RentalAgreement.Labels.planned", "Planwert")}{" "}
                    {StringUtils.formatCurrency(
                      plannedRent.data["general"]?.rentGross,
                      true
                    )}
                    )
                  </span>
                ) : null}
              </>
            }
          />
        )}
        <BFValueDisplay
          label={i18n.t(
            "cb:RentalAgreement.Labels.operatingCostNet",
            "Nebenkosten netto"
          )}
          value={
            <>
              <span className={`should`}>
                {StringUtils.formatCurrency(
                  props.rentalAgreement.data.operatingCostNet,
                  true
                )}
              </span>{" "}
              {plannedRent.data ? (
                <span className={`plan`}>
                  ({i18n.t("cb:RentalAgreement.Labels.planned", "Planwert")}{" "}
                  {StringUtils.formatCurrency(
                    plannedRent.data["general"]?.operatingCostNet,
                    true
                  )}
                  )
                </span>
              ) : null}
            </>
          }
        />
        {props.rentalAgreement.data.taxable && (
          <BFValueDisplay
            label={i18n.t("cb:RentalAgreement.Labels.rentNet", "Miete netto")}
            value={
              <>
                <span className={`should`}>
                  {StringUtils.formatCurrency(
                    props.rentalAgreement.data.operatingCostGross,
                    true
                  )}
                </span>{" "}
                {plannedRent.data ? (
                  <span className={`plan`}>
                    ({i18n.t("cb:RentalAgreement.Labels.planned", "Planwert")}{" "}
                    {StringUtils.formatCurrency(
                      plannedRent.data["general"]?.operatingCostGross,
                      true
                    )}
                    )
                  </span>
                ) : null}
              </>
            }
          />
        )}
      </div>
    </RentalAgreementSection>
  );
};

const IndexData = (props: { rentalAgreement: RentalAgreement }) => {
  const index = props.rentalAgreement.data.index;
  return (
    <RentalAgreementSection
      title={i18n.t("cb:RentalAgreement.Labels.IndexData", "Indexierung")}
      onEdit={
        OrgaStruct.isImportedObject(props.rentalAgreement.data.objectId)
          ? undefined
          : () => {
              ModalManager.show({
                size: "sm",
                noPadding: true,
                content: (state, setState, close) => {
                  return (
                    <CBRentalAgreementIndexUpdateForm
                      rentalAgreement={props.rentalAgreement}
                      onClose={close}
                    />
                  );
                },
              });
            }
      }
    >
      <>
        {hasNoValue(index) && (
          <div className={`__empty`}>
            {i18n.t(
              "cb:RentalAgreement.Labels.NoIndex",
              "Für den Vertrag wurde keine Indexierung hinterlegt"
            )}
          </div>
        )}
        {hasValue(index) && (
          <>
            <div className={`__flex-wrap padding-bottom-10`}>
              <BFValueDisplay
                label={i18n.t("cb:RentalAgreement.Labels.Index.type", "Art")}
                value={index.type}
                formatter={(value) =>
                  value === "default"
                    ? i18n.t(
                        "cb:RentalAgreement.Form.Index.defaultType",
                        "Normal"
                      )
                    : i18n.t(
                        "cb:RentalAgreement.Form.Index.yearlyType",
                        "Jährlich"
                      )
                }
              />
              {index.type === "default" && (
                <BFValueDisplay
                  label={i18n.t(
                    "cb:RentalAgreement.Labels.Index.indexFactor",
                    "Erhöhung bei Index-Veränderung"
                  )}
                  value={index.indexFactor}
                  formatter={(value) => StringUtils.formatPercent(value)}
                />
              )}
              {index.type === "yearly" && (
                <BFValueDisplay
                  label={i18n.t(
                    "cb:RentalAgreement.Labels.Index.indexMonth",
                    "Monat"
                  )}
                  value={index.indexMonth}
                  formatter={(value) => moment().month(value).format("MMMM")}
                />
              )}

              <BFValueDisplay
                label={i18n.t(
                  "cb:RentalAgreement.Labels.Index.lastPriceIndex",
                  "Letzter Indexierungsfaktor"
                )}
                value={index.lastPriceIndex}
              />

              <BFValueDisplay
                label={i18n.t(
                  "cb:RentalAgreement.Labels.Index.lastPriceIndexChange",
                  "Datum der Indexierung"
                )}
                value={index.lastPriceIndexChange}
                formatter={StringUtils.formatDate}
              />
              <BFValueDisplay
                label={i18n.t(
                  "cb:RentalAgreement.Labels.Index.ignoreUntil",
                  "Indexierungssperre bis"
                )}
                value={index.ignoreUntil}
                formatter={StringUtils.formatDate}
              />
              <BFValueDisplay
                label={i18n.t(
                  "cb:RentalAgreement.Labels.Index.backPayPossible",
                  "Rückzahlung möglich"
                )}
                value={index.backPayPossible}
                formatter={(value) => StringUtils.formatBoolean(value)}
              />
            </div>
            <div className={`__flex-wrap padding-bottom-10`}>
              <BFValueDisplay
                label={i18n.t(
                  "cb:RentalAgreement.Labels.Index.paragraph",
                  "Absatz im Mietvertrag"
                )}
                value={index.paragraph}
              />

              <BFValueDisplay
                label={i18n.t(
                  "cb:RentalAgreement.Labels.Index.agreementLabel",
                  "Mietvertragsname"
                )}
                value={index.agreementLabel}
              />
            </div>

            <div className={`__flex`}>
              <BFValueDisplay
                label={i18n.t("cb:RentalAgreement.Labels.Index.note", "Notiz")}
                value={index.note}
                formatter={(value) => (
                  <HTMLText withBorder maxHeight={400} text={value} />
                )}
              />
            </div>
          </>
        )}
      </>
    </RentalAgreementSection>
  );
};

const OptionData = (props: { rentalAgreement: RentalAgreement }) => {
  const option = props.rentalAgreement.data.option;
  return (
    <RentalAgreementSection
      title={i18n.t("cb:RentalAgreement.Labels.OptionData", "Option")}
      onEdit={
        OrgaStruct.isImportedObject(props.rentalAgreement.data.objectId)
          ? undefined
          : () => {
              ModalManager.show({
                size: "sm",
                noPadding: true,
                content: (state, setState, close) => {
                  return (
                    <CBRentalAgreementOptionUpdateForm
                      rentalAgreement={props.rentalAgreement}
                      onClose={close}
                    />
                  );
                },
              });
            }
      }
    >
      <>
        {hasNoValue(option) && (
          <div className={`__empty`}>
            {i18n.t(
              "cb:RentalAgreement.Labels.NoOption",
              "Für den Vertrag wurde keine Option hinterlegt"
            )}
          </div>
        )}
        {hasValue(option) && (
          <>
            {option.map((option, index) => (
              <div className={`option-entry`}>
                <div className={`title`}>
                  {i18n.t("cb:RentalAgreement.Labels.Option.option", "Option")}{" "}
                  {index + 1}
                </div>

                <div className={`__flex-wrap padding-bottom-10`}>
                  <BFValueDisplay
                    label={i18n.t(
                      "cb:RentalAgreement.Labels.Option.until",
                      "Option bis"
                    )}
                    value={option.optionUntil}
                    formatter={StringUtils.formatDate}
                  />
                  <BFValueDisplay
                    label={i18n.t(
                      "cb:RentalAgreement.Labels.Option.optionBy",
                      "Option von"
                    )}
                    value={option.optionBy}
                    formatter={(value) =>
                      RentalAgreementOptionBy().find((e) => e.value === value)
                        ?.label
                    }
                  />
                  <BFValueDisplay
                    label={i18n.t(
                      "cb:RentalAgreement.Labels.Option.extendsByMonths",
                      "Verlängerung um"
                    )}
                    value={option.extendsByMonths}
                    formatter={(value) =>
                      `${value} ${i18n.t("Global.Labels.months", "Monate")}`
                    }
                  />
                  <BFValueDisplay
                    label={i18n.t(
                      "cb:RentalAgreement.Labels.Option.optionsUsed",
                      "Option verwendet"
                    )}
                    value={StringUtils.formatBoolean(option.optionUsed)}
                  />
                </div>

                <div className={`__flex`}>
                  <BFValueDisplay
                    label={i18n.t(
                      "cb:RentalAgreement.Labels.Option.note",
                      "Notiz"
                    )}
                    value={option.note}
                    formatter={(value) => <WrappedText text={value} />}
                  />
                </div>
              </div>
            ))}
          </>
        )}
      </>
    </RentalAgreementSection>
  );
};
const AutomaticData = (props: { rentalAgreement: RentalAgreement }) => {
  const automatic = props.rentalAgreement.data.automatic;
  return (
    <RentalAgreementSection
      title={i18n.t(
        "cb:RentalAgreement.Labels.AutomaticData",
        "Auszugsautomatik"
      )}
      onEdit={
        OrgaStruct.isImportedObject(props.rentalAgreement.data.objectId)
          ? undefined
          : () => {
              Log.info("option data on edit");
              ModalManager.show({
                size: "sm",
                noPadding: true,
                content: (state, setState, close) => {
                  return (
                    <CBRentalAgreementAutomaticUpdateForm
                      rentalAgreement={props.rentalAgreement}
                      onClose={close}
                    />
                  );
                },
              });
            }
      }
    >
      <>
        {hasNoValue(automatic) && (
          <div className={`__empty`}>
            {i18n.t(
              "cb:RentalAgreement.Labels.NoAutomatic",
              "Für den Vertrag wurde keine Auszugsautomatik hinterlegt"
            )}
          </div>
        )}
        {hasValue(automatic) && (
          <>
            <div className={`__flex-wrap padding-bottom-10`}>
              <BFValueDisplay
                label={i18n.t(
                  "cb:RentalAgreement.Labels.Automatic.monthBeforeContractEnd",
                  "Vor Auszug"
                )}
                value={automatic.monthBeforeContractEnd}
                formatter={(value) =>
                  `${value} ${i18n.t("Global.Labels.months", "Monate")}`
                }
              />
              <BFValueDisplay
                label={i18n.t(
                  "cb:RentalAgreement.Labels.Automatic.extendByMonths",
                  "Verlängern um"
                )}
                value={automatic.extendByMonths}
                formatter={(value) =>
                  `${value} ${i18n.t("Global.Labels.months", "Monate")}`
                }
              />
            </div>
          </>
        )}
      </>
    </RentalAgreementSection>
  );
};

const ContactData = (props: { rentalAgreement: RentalAgreement }) => {
  const tenant = props.rentalAgreement.data.tenant;
  const contactOption = props.rentalAgreement.data.contactOption;
  return (
    <RentalAgreementSection
      title={i18n.t("cb:RentalAgreement.Labels.Tenant", "Mieter")}
      onEdit={
        OrgaStruct.isImportedObject(props.rentalAgreement.data.objectId)
          ? undefined
          : () => {
              ModalManager.show({
                size: "sm",
                noPadding: true,
                content: (state, setState, close) => {
                  return (
                    <CBRentalAgreementContactOptionUpdateForm
                      rentalAgreement={props.rentalAgreement}
                      onClose={close}
                    />
                  );
                },
              });
            }
      }
    >
      <>
        <AssetLoader
          assetType={AssetTypes.Contact}
          id={props.rentalAgreement.data.tenant}
          render={(tenant: Contact) => (
            <div className={`__flex-wrap padding-bottom-10`}>
              <BFValueDisplay
                label={i18n.t("cb:Tenant.Labels.Name", "Mieter")}
                value={
                  <BFDetailsButton
                    appearance="link"
                    noPadding
                    data={{
                      assetType: AssetTypes.Contact,
                      assetId: tenant._id,
                      type: tenant.data.type,
                      params: {
                        contactType: "TENANT",
                      },
                    }}
                  >
                    {tenant.data.displayName}
                  </BFDetailsButton>
                }
              />

              <BFValueDisplay
                label={i18n.t(
                  "cb:RentalAgreement.Labels.Contact.addressId",
                  "Kontaktadresse"
                )}
                value={contactOption?.addressId}
                formatter={(value) =>
                  ContactUtils.getAdressSelectOptions(tenant)?.find(
                    (e) => e.value === value
                  )?.label
                }
              />

              <BFValueDisplay
                label={i18n.t(
                  "cb:RentalAgreement.Labels.Contact.contactPersonId",
                  "Kontaktperson"
                )}
                value={contactOption?.contactPersonId}
                formatter={(value) =>
                  ContactUtils.getContactPersonSelectOptions(tenant)?.find(
                    (e) => e.value === value
                  )?.label
                }
              />

              <BFValueDisplay
                label={i18n.t(
                  "cb:RentalAgreement.Labels.Contact.mail",
                  "Kontakt E-Mail"
                )}
                value={contactOption?.mail}
                formatter={(value) =>
                  ContactUtils.getEmailSelectOptions(tenant)?.find(
                    (e) => e.value === value
                  )?.label
                }
              />

              <BFValueDisplay
                label={i18n.t(
                  "cb:RentalAgreement.Labels.Contact.phone",
                  "Kontakt Telefon"
                )}
                value={contactOption?.phone}
                formatter={(value) =>
                  ContactUtils.getPhoneSelectOptions(tenant)?.find(
                    (e) => e.value === value
                  )?.label
                }
              />
              <BFValueDisplay
                label={i18n.t(
                  "cb:RentalAgreement.Labels.Contact.sms",
                  "Kontakt SMS"
                )}
                value={contactOption?.sms}
                formatter={(value) =>
                  ContactUtils.getPhoneSelectOptions(tenant)?.find(
                    (e) => e.value === value
                  )?.label
                }
              />
            </div>
          )}
        />
      </>
    </RentalAgreementSection>
  );
};
const GraduatedRentData = (props: {
  rentalAgreement: RentalAgreement;
  kind: ObjectKind;
}) => {
  if (!props.rentalAgreement.data.graduatedRent) {
    return null;
  }

  const reasonId = (
    props.kind.data.features.find(
      (e) => e.type === "immo"
    ) as ObjectKindFeature_IMMO
  )?.graduatedRentReason;
  return (
    <RentalAgreementSection
      title={i18n.t("cb:RentalAgreement.Labels.GraduatedRent", "Staffelmiete")}
    >
      <div>
        <ListComponent
          assetType={AssetTypes.Rental.RentalAgreementPeriod}
          identifier="graduatedRent"
          cleanupOnUnmount
          additionalMatchQuery={MQ.and(
            MQ.eq("data.status", "active"),
            MQ.eq("data.rentalAgreement", props.rentalAgreement._id),
            MQ.when(reasonId, MQ.eq("data.reason", reasonId))
          )}
          render={(period: RentalAgreementTimePeriod) => (
            <div className={`__flex padding-bottom-5`}>
              <div>
                {i18n.t("Global.Labels.fromDate")}{" "}
                {StringUtils.formatDate(period.data.from)}
              </div>
              <div>
                {props.rentalAgreement.data.taxable ? (
                  <span>
                    {StringUtils.formatCurrency(period.data.rentNet)}{" "}
                    {i18n.t("cb:RentalAgreement.Labels.RentNet", "Miete Netto")}{" "}
                    / {StringUtils.formatCurrency(period.data.operatingCostNet)}{" "}
                    {i18n.t(
                      "cb:RentalAgreement.Labels.OperatingCostNet",
                      "Nebenkosten Netto"
                    )}
                  </span>
                ) : (
                  <span>
                    {StringUtils.formatCurrency(period.data.rentGross)}{" "}
                    {i18n.t(
                      "cb:RentalAgreement.Labels.RentGross",
                      "Miete Brutto"
                    )}{" "}
                    /{" "}
                    {StringUtils.formatCurrency(period.data.operatingCostGross)}{" "}
                    {i18n.t(
                      "cb:RentalAgreement.Labels.OperatingCostGross",
                      "Nebenkosten Brutto"
                    )}
                  </span>
                )}
              </div>
            </div>
          )}
        />
      </div>
    </RentalAgreementSection>
  );
};

const RentalAgreementSection = (props: {
  title: string;
  onEdit?: () => void;
  children: React.ReactNode;
}) => {
  return (
    <div className={`rental-agreement-section`}>
      <div className={`section-title`}>
        <div className={`__h3`}>{props.title}</div>
        {props.onEdit && (
          <BFButton
            noPadding
            appearance="link"
            onClick={props.onEdit}
            size="sm"
          >
            {i18n.t("Global.Buttons.Customize", "Anpassen")}
          </BFButton>
        )}
      </div>
      <div className={`section-content`}>{props.children}</div>
    </div>
  );
};
