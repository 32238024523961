import FormFieldValues from "@/components/Form/Fields/FormFieldValues";
import FormStruct from "@/components/Form/FormStruct/FormStruct";
import OnChange from "@/components/Form/Helpers/OnChange";
import { FV } from "@/components/Form/Validation/FormValidators";
import ModalManager from "@/components/ModalComponent/ModalManager";
import i18n from "@/i18n";
import BFCheckbox from "@/modules/abstract-ui/forms/checkbox/BFCheckbox";
import { BFChooserOption } from "@/modules/abstract-ui/forms/chooser/BFChooser";
import BFChooserSelect from "@/modules/abstract-ui/forms/chooser/BFChooserSelect";
import BFDatefield from "@/modules/abstract-ui/forms/datefield/BFDatefield";
import BFInput from "@/modules/abstract-ui/forms/input/BFInput";
import BFTexteditor from "@/modules/abstract-ui/forms/input/BFTexteditor";
import BFButton from "@/modules/abstract-ui/general/Button/BFButton";
import { isDefined } from "@/utils/Helpers";
import moment from "moment";
import { useState } from "react";
import { Field } from "react-final-form";
import CBRentalService from "../../../CBRentalService";
import { RentalAgreement } from "../../../TenantsInterfaces";
// import "./CBRentalAgreementIndexForm.scss";

interface CBRentalAgreementIndexFormProps {
  force?: boolean;
}
const CBRentalAgreementIndexForm = (props: CBRentalAgreementIndexFormProps) => {
  return (
    <Field name="index" allowNull defaultValue={null}>
      {(index) => {
        if (!props.force && index.input.value === null) {
          return null;
        }
        return (
          <div className={`index-form`}>
            <div
              className={`__flex section-title __h3  margin-top-10 margin-bottom-10`}
            >
              <div className={`title`}>
                {i18n.t(
                  "cb:RentalAgreement.Form.SectionTitle.Index",
                  "Indexmiete"
                )}
              </div>
              {!props.force && (
                <BFButton
                  onClick={() => index.input.onChange(null)}
                  appearance="link"
                  size="xs"
                  noPadding
                  className={`margin-left-10`}
                >
                  {i18n.t("Global.Buttons.remove")}
                </BFButton>
              )}
            </div>
            <Field name="index.type" validate={FV.compose(FV.required())}>
              {(indexType) => (
                <>
                  <div className={`__flex`}>
                    <div className={`__flex-1 __field`}>
                      <BFChooserSelect
                        {...indexType.input}
                        {...FV.getValidation(indexType.meta)}
                        label={
                          i18n.t(
                            "cb:RentalAgreement.Form.Index.type",
                            "Indexierungsart"
                          ) + "*"
                        }
                        hideSearch
                        data={[
                          {
                            value: "default",
                            label: i18n.t(
                              "cb:RentalAgreement.Form.Index.defaultType",
                              "Normal"
                            ),
                          },
                          {
                            value: "yearly",
                            label: i18n.t(
                              "cb:RentalAgreement.Form.Index.yearlyType",
                              "Jährlich"
                            ),
                          },
                        ]}
                      />
                    </div>

                    <FormFieldValues names={["moveIn"]}>
                      {([moveIn]) => {
                        const isMoveInLaterThanNow =
                          isDefined(moveIn) &&
                          moment(moveIn).isBefore(moment());
                        return (
                          <>
                            <div className={`__flex-2 __field`}>
                              <Field
                                name="index.lastPriceIndex"
                                validate={
                                  !isMoveInLaterThanNow
                                    ? undefined
                                    : FV.compose(FV.required())
                                }
                              >
                                {(lastPriceIndex) => (
                                  <OnChange
                                    value={moveIn}
                                    onChange={(value) => {
                                      if (value) {
                                      }
                                    }}
                                  >
                                    <>
                                      {!isMoveInLaterThanNow && (
                                        <BFInput
                                          label={
                                            i18n.t(
                                              "cb:RentalAgreement.Form.Index.lastPriceIndex",
                                              "VPI bei Start"
                                            ) + "*"
                                          }
                                          disabled
                                          value={
                                            i18n.t(
                                              "cb:RentalAgreement.Form.Index.willBeSetOnRentStart",
                                              "Wird bei Mietbeginn errechnet"
                                            ) as string
                                          }
                                        />
                                      )}
                                      {isMoveInLaterThanNow && (
                                        <BFInput
                                          label={i18n.t(
                                            "cb:RentalAgreement.Form.Index.lastPriceIndex",
                                            "Preisindex"
                                          )}
                                          {...lastPriceIndex.input}
                                          {...FV.getValidation(
                                            lastPriceIndex.meta
                                          )}
                                          type="number"
                                          step={0.01}
                                        />
                                      )}
                                    </>
                                  </OnChange>
                                )}
                              </Field>
                            </div>

                            <div className={`__flex-2  __field`}>
                              <Field
                                name="index.lastPriceIndexChange"
                                validate={
                                  !isMoveInLaterThanNow
                                    ? undefined
                                    : FV.compose(FV.dateBefore(new Date()))
                                }
                              >
                                {(lastPriceIndexChange) => (
                                  <>
                                    {!isMoveInLaterThanNow && (
                                      <BFInput
                                        label={
                                          i18n.t(
                                            "cb:RentalAgreement.Form.Index.lastPriceIndexChange",
                                            "Letzte Mietanpassung"
                                          ) + "*"
                                        }
                                        disabled
                                        value={
                                          i18n.t(
                                            "cb:RentalAgreement.Form.Index.willBeSetOnRentStart",
                                            "Wird bei Mietbeginn errechnet"
                                          ) as string
                                        }
                                      />
                                    )}
                                    {isMoveInLaterThanNow && (
                                      <BFDatefield
                                        {...lastPriceIndexChange.input}
                                        {...FV.getValidation(
                                          lastPriceIndexChange.meta
                                        )}
                                        label={
                                          i18n.t(
                                            "cb:RentalAgreement.Form.Index.lastPriceIndexChange",
                                            "Letzte Mietanpassung"
                                          ) + "*"
                                        }
                                      />
                                    )}
                                  </>
                                )}
                              </Field>
                            </div>
                          </>
                          // moveIn && moment(moveIn).isBefore(moment()) ? (
                          //   <div>
                          //     todo select preisindex
                          //   </div>
                          // ) : (
                          //   <div>
                          //     todo info about wil be set automatically
                          //   </div>
                          // )
                        );
                      }}
                    </FormFieldValues>
                  </div>
                  <div className={`__flex`}>
                    <div className={`__flex-1 __field`}>
                      {indexType.input.value === "yearly" && (
                        <Field
                          name="index.indexMonth"
                          validate={FV.compose(FV.required())}
                        >
                          {(indexMonth) => (
                            <BFChooserSelect
                              {...indexMonth.input}
                              {...FV.getValidation(indexMonth.meta)}
                              label={
                                i18n.t(
                                  "cb:RentalAgreement.Form.Index.indexMonth",
                                  "Indexierungsmonat"
                                ) + "*"
                              }
                              data={new Array(12).fill(0).map(
                                (_, i) =>
                                  ({
                                    value: (i + 1).toString(),
                                    label: moment().month(i).format("MMMM"),
                                  } as BFChooserOption<string>)
                              )}
                            />
                          )}
                        </Field>
                      )}
                      {indexType.input.value === "default" && (
                        <Field
                          name="index.indexFactor"
                          validate={FV.compose(FV.required())}
                        >
                          {(indexMonth) => (
                            <BFInput
                              type="number"
                              {...indexMonth.input}
                              {...FV.getValidation(indexMonth.meta)}
                              label={
                                i18n.t(
                                  "cb:RentalAgreement.Form.Index.indexFactor",
                                  "Indexierungsschwelle"
                                ) + "*"
                              }
                              step={0.01}
                              prefix="%"
                            />
                          )}
                        </Field>
                      )}
                    </div>
                    <div className={`__flex-1 __field`}>
                      <Field
                        name="index.rentChangeFactor"
                        validate={FV.compose(FV.required())}
                      >
                        {(indexMonth) => (
                          <BFInput
                            type="number"
                            {...indexMonth.input}
                            {...FV.getValidation(indexMonth.meta)}
                            label={
                              i18n.t(
                                "cb:RentalAgreement.Form.Index.rentChangeFactor",
                                "Mietanpassungsfaktor"
                              ) + "*"
                            }
                            step={0.01}
                            prefix="%"
                          />
                        )}
                      </Field>
                    </div>
                    <div className={`__flex-1 __field`}>
                      <Field name="index.ignoreUntil">
                        {(ignoreUntil) => (
                          <BFDatefield
                            {...ignoreUntil.input}
                            {...FV.getValidation(ignoreUntil.meta)}
                            label={i18n.t(
                              "cb:RentalAgreement.Form.Index.ignoreUntil",
                              "Indexierungssperre bis"
                            )}
                          />
                        )}
                      </Field>
                    </div>
                  </div>
                  <div className={`__flex`}>
                    <div className={`__flex-1 __field`}>
                      <Field
                        name="index.paragraph"
                        validate={FV.compose(FV.required())}
                      >
                        {(paragraph) => (
                          <BFInput
                            {...paragraph.input}
                            {...FV.getValidation(paragraph.meta)}
                            label={
                              i18n.t(
                                "cb:RentalAgreement.Form.Index.paragraph",
                                "Absatz im Mietvertrag"
                              ) + "*"
                            }
                          />
                        )}
                      </Field>
                    </div>
                    <div className={`__flex-1`}>
                      <Field
                        name="index.agreementLabel"
                        validate={FV.compose(FV.required())}
                      >
                        {(paragraph) => (
                          <BFInput
                            {...paragraph.input}
                            {...FV.getValidation(paragraph.meta)}
                            label={
                              i18n.t(
                                "cb:RentalAgreement.Form.Index.agreementLabel",
                                "Mietvertragsname"
                              ) + "*"
                            }
                          />
                        )}
                      </Field>
                    </div>
                    <div className={`__flex-1 padding-top-20`}>
                      <Field name="index.backPayPossible">
                        {(paragraph) => (
                          <BFCheckbox
                            checked={paragraph.input.value}
                            onChange={(_, checked) =>
                              paragraph.input.onChange(checked)
                            }
                          >
                            {i18n.t(
                              "cb:RentalAgreement.Form.Index.backPayPossible",
                              "Nachzahlung möglich"
                            )}
                          </BFCheckbox>
                        )}
                      </Field>
                    </div>
                  </div>
                  <div className={`__flex`}>
                    <div className={`__flex-1`}>
                      <Field name="index.note">
                        {(note) => (
                          <BFTexteditor
                            maxHeight={400}
                            label={i18n.t(
                              "cb:RentalAgreement.Form.Index.noteText",
                              "Notiz"
                            )}
                            {...note.input}
                            {...FV.getValidation(note.meta)}
                          />
                          // <BFInput
                          //   type="textarea"
                          //   autoResize
                          //   {...paragraph.input}
                          //   {...FV.getValidation(paragraph.meta)}
                          //   label={i18n.t(
                          //     "cb:RentalAgreement.Form.Index.noteText",
                          //     "Notiz"
                          //   )}
                          // />
                        )}
                      </Field>
                    </div>
                  </div>
                </>
              )}
            </Field>
          </div>
        );
      }}
    </Field>
  );
};

export default CBRentalAgreementIndexForm;

const getInitial = () => ({
  type: "default",
  indexFactor: 5,
  rentChangeFactor: 100,
  agreementLabel: i18n.t(
    "cb:RentalAgreement.Form.Index.agreementLabelDefault",
    "Mietvertrag"
  ),
  backPayPossible: false,
});
export const CBRentalAgreementIndexFormButton = () => {
  return (
    <Field name="index" allowNull defaultValue={null}>
      {({ input, meta }) => {
        if (!isDefined(input.value)) {
          return (
            <BFButton
              type="button"
              appearance="outline"
              size="xs"
              onClick={() => input.onChange(getInitial())}
              text={i18n.t(
                "cb:RentalAgreement.Form.Buttons.addIndex",
                "Indexmiete hinzufügen"
              )}
            />
          );
        } else {
          return null;
        }
      }}
    </Field>
  );
};

export const CBRentalAgreementIndexUpdateForm = (props: {
  rentalAgreement: RentalAgreement;
  onClose: () => void;
}) => {
  const [initialValues] = useState({
    moveIn: props.rentalAgreement.data.moveIn,
    index: props.rentalAgreement.data.index || getInitial(),
  });
  return (
    <FormStruct
      onSubmit={async (values) => {
        await CBRentalService.submitRentalAgreementIndex(
          props.rentalAgreement._id,
          values.index
        );
        props.onClose();
      }}
      initialValues={initialValues}
      onAbort={props.onClose}
      title={i18n.t(
        i18n.t("cb:RentalAgreement.Form.SectionTitle.Index", "Indexmiete")
      )}
      submitText={i18n.t("Global.Buttons.save")}
      additionalActions={
        <BFButton
          appearance="outline"
          onClick={async () => {
            ModalManager.confirm({
              title: i18n.t(
                "cb:RentalAgreement.Form.Delete.Index.title",
                "Index entfernen"
              ),
              message: i18n.t(
                "cb:RentalAgreement.Form.Delete.INdex.description",
                "Wollen Sie die Indexierung entfernen?"
              ),
              onConfirm: () => {
                CBRentalService.removeRentalAgreementIndex(
                  props.rentalAgreement._id
                ).then(() => {
                  props.onClose();
                });
              },
            });
          }}
        >
          {i18n.t("Global.Buttons.delete")}
        </BFButton>
      }
      render={(formProps) => (
        <>
          <CBRentalAgreementIndexForm force />
        </>
      )}
    />
  );
};
