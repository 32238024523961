import {
  ObjectKind,
  ObjectKindFeature_IMMO,
} from "@/apps/tatar/objectsApp/types/objectKind.interface";
import i18n from "@/i18n";
import ServiceUtils from "@/services/ServiceUtils";
import _ from "lodash";
import moment from "moment";
import { nanoid } from "nanoid";
import { AssetTypes } from "../../../../../model/AssetTypes";
import { Contact } from "../../../../../model/db/Contact";
import CacheService from "../../../../../services/CacheService";
import ContactService from "../../../../../services/ContactService";
import DataBus from "../../../../../services/DataBus";
import SubmitService from "../../../../../services/SubmitService";
import { DataBusSubKeys } from "../../../../../utils/Constants";
import { HTTP } from "../../../../../utils/Http";
import {
  AgreementOption,
  ContactOption,
  DepositPosition,
  IndexOption,
  PaymentPosition,
  RentalAgreementAutomatic,
  RentalAgreementTimePeriod,
  RentalAgreementUnit,
  RentalUnit,
  RentalUnitFormValue,
  RentalVacancy,
  RentalVacancyAdvertisement,
  RentPaymentTimingOption,
  SubmitObjectPlanData,
} from "./TenantsInterfaces";
import { RentalOpos } from "./components/rental-opos/OposInterfaces";

class CBRentalServiceClass {
  async updateVacantAdvertisement(
    rentalVacancy: RentalVacancy,
    id: string,
    data: Partial<RentalVacancyAdvertisement>
  ) {
    await SubmitService.submitDataAsync({
      assetType: AssetTypes.Rental.RentalVacancy,
      type: "asset",
      data: {
        _id: rentalVacancy._id,
        data: {
          advertisements: (rentalVacancy.data.advertisements || []).map(
            (advertismement) =>
              advertismement.id === id
                ? { ...advertismement, ...data }
                : advertismement
          ),

          // [
          //   ...rentalVacancy.data.advertisements,
          //   { ...data, id: nanoid() },
          // ],
        },
      },
      pushToCache: true,
      ignorePropChecks: true,
      ignoreSubmitValidation: true,
    });
  }
  async addVacantAdvertisement(
    rentalVacancy: RentalVacancy,
    data: Partial<RentalVacancyAdvertisement>
  ) {
    await SubmitService.submitDataAsync({
      assetType: AssetTypes.Rental.RentalVacancy,
      type: "asset",
      data: {
        _id: rentalVacancy._id,
        data: {
          advertisements: [
            ...(rentalVacancy.data.advertisements || []),
            { ...data, id: nanoid() },
          ],
        },
      },
      pushToCache: true,
      ignorePropChecks: true,
      ignoreSubmitValidation: true,
    });
  }
  async removeVacantAdvertisement(rentalVacancy: RentalVacancy, id: string) {
    await SubmitService.submitDataAsync({
      assetType: AssetTypes.Rental.RentalVacancy,
      type: "asset",
      data: {
        _id: rentalVacancy._id,
        data: {
          advertisements: (rentalVacancy.data.advertisements || []).filter(
            (e) => e.id !== id
          ),
        },
      },
      pushToCache: true,
      ignorePropChecks: true,
      ignoreSubmitValidation: true,
    });
  }

  async addTimePeriodToAgreement(
    agreementId: string,
    data: {
      from: Date;
      to?: Date;
      paymentPositions: PaymentPosition[];
      reason: string;
      comment?: string;
      rentalUnits: string[];
      taxable: "taxable" | "notTaxable";
      rentPaymentTimingOptions: RentPaymentTimingOption;
    }
  ) {
    const result = await HTTP.post({
      url: `/rental/${agreementId}/insertTimePeriod`,
      bodyParams: {
        rentalUnits: data.rentalUnits,
        from: moment(data.from).toISOString(),
        to: data.to ? moment(data.to).toISOString() : null,
        paymentPositions: data.paymentPositions,
        reason: data.reason,
        note: data.comment || "",
        taxable: data.taxable === "taxable",
        rentPaymentTimingOptions: data.rentPaymentTimingOptions,
      },
      target: "EMPTY",
    });
    return result;
  }

  async updateRentOfAgreeement(
    agreementId,
    data: {
      rentNet: number;
      rentGross: number;
      operatingCostNet: number;
      operatingCostGross: number;
      from: Date;
      to: Date;
      reason: string;
      comment: string;
    }
  ) {
    const result = await HTTP.post({
      url: `/rental/agreement/${agreementId}/changeRent`,
      bodyParams: {
        rentNet: data.rentNet,
        rentGross: data.rentGross,
        operatingCostNet: data.operatingCostNet,
        operatingCostGross: data.operatingCostGross,
        from: moment(data.from).toISOString(),
        to: data.to ? moment(data.to).toISOString() : undefined,
        // reason: data.reason,
        // comment: data.comment,
      },
      target: "EMPTY",
    });
    CacheService.updateDataInCaches(result._id, result);
    return result;
  }

  async updateRentalUnitsOfAgreement(
    agreementId: string,
    rentalUnits: RentalAgreementUnit[]
  ) {
    const result = await HTTP.post({
      url: `/rental/agreement/${agreementId}/changeRentalUnit`,
      bodyParams: {
        rentalUnitsInfo: rentalUnits.map((e) => ({
          rentalUnitId: e.rentalUnitId,
          from: moment(e.from).toISOString(),
          to: e.to ? moment(e.to).toISOString() : undefined,
        })),
      },
      target: "EMPTY",
    });
    CacheService.updateDataInCaches(result._id, result);
    return result;
  }

  async submitRentalAgreement(
    kind: ObjectKind,
    values: any,
    additionalAttachmentsMetadata?: any,
    additionalDepositAttachmentsMetadata?: any
  ) {
    return await ServiceUtils.toastError(
      async () => {
        const deposits = (values.deposits || []).map(
          ({ document, ...depositData }) => ({
            ...depositData,
          })
        );

        const depositAttachments = (values.deposits || [])
          .map((e) => e.document)
          .filter((e) => e)
          .flat();

        const data = {
          type: values.type,
          rentalUnits: values.rentalUnits.map((e) => e.assetId),
          tenant: values.tenant,
          id: values.id,
          contractType: values.contractType,
          displayName: values.displayName,
          moveIn: moment(values.moveIn).toISOString(),
          moveOut: values.moveOut ? moment(values.moveOut).toISOString() : null,
          agreementExpiration: values.agreementExpiration
            ? moment(values.agreementExpiration).toISOString()
            : null,
          paymentPositions: values.paymentPositions?.map((position) => ({
            id: position.id,
            net: position.net,
            gross: values.taxable ? position.gross : 0,
          })),
          rentPaymentTimingOptions: values.rentPaymentTimingOptions,
          taxable: values.taxable === "taxable" ? true : false,
          deposit: deposits,
          note: values.note,
          option: values.option || null,
          contactOption: values.contactOption || null,
          graduatedRent: values.graduatedRent?.length > 0,
          index: values.index || null,
          automatic: values.automatic || null,
        };

        let result;
        result = await HTTP.post({
          url: `/rental/${values.objectId}/createRentalAgreement`,
          target: "EMPTY",
          bodyParams: data,
        });

        for (const graduatedRent of values.graduatedRent || []) {
          await CBRentalService.addTimePeriodToAgreement(result._id, {
            from: graduatedRent.fromDate,
            to: null,
            reason: (
              kind.data.features.find(
                (e) => e.type === "immo"
              ) as ObjectKindFeature_IMMO
            )?.graduatedRentReason,
            comment: i18n.t(
              "cb:RentalAgreement.Form.graduatedRentDefaultText",
              "Geplante Mietpreisanpassung laut Vertrag"
            ),
            paymentPositions: graduatedRent.paymentPositions?.map(
              (position) => ({
                id: position.id,
                net: position.net,
                gross: values.taxable ? position.gross : 0,
              })
            ),
            taxable: values.taxable,
            rentalUnits: values.rentalUnits.map((e) => e.assetId),
            rentPaymentTimingOptions: values.rentPaymentTimingOptions,
          });
        }

        result = await ServiceUtils.handleCDNFiles(
          result,
          AssetTypes.Rental.RentalAgreement,
          "data.attachments",
          {
            new: values.rentalAgreement,
          },
          additionalAttachmentsMetadata
        );

        if (depositAttachments) {
          await ServiceUtils.handleCDNFiles(
            result,
            AssetTypes.Rental.RentalAgreement,
            "data.attachments",
            {
              new: depositAttachments,
            },
            additionalDepositAttachmentsMetadata
          );
        }

        DataBus.emit(DataBusSubKeys.STACKING_PLAN_RELOAD, {
          objectId: result.data.objectId,
        });

        return result;
      },
      async (err) => {
        const specialHandlingCodes = [
          "RENTAL_UNIT_OCCUPIED",
          "RENTAL_AGREEMENT_ID_ALREADY_EXISTS",
        ];
        if (specialHandlingCodes.includes(err.response?.data?.code)) {
          const errData = err.response.data;

          if (errData.code === "RENTAL_UNIT_OCCUPIED") {
            const periodIds = errData.message;
            const timePeriods: RentalAgreementTimePeriod[] = await Promise.all(
              periodIds.map((periodId) =>
                CacheService.getData({
                  oType: "asset",
                  assetType: AssetTypes.Rental.RentalAgreementPeriod,
                  id: periodId,
                })
              )
            );

            const affectedTimeperiods = timePeriods
              .map((period) => period.data.rentalUnitIds)
              .flat();
            const overlappingRentalUnitIds = _.intersection(
              affectedTimeperiods,
              values.rentalUnits
            );

            const units: RentalUnit[] = await Promise.all(
              overlappingRentalUnitIds.map((unitId) =>
                CacheService.getData({
                  oType: "asset",
                  assetType: AssetTypes.Rental.RentalUnit,
                  id: unitId,
                })
              )
            );
            return `${i18n.t(
              "cb:RentalAgreement.Form.Errors.RENTAL_UNIT_OCCUPIED",
              "Folgende Mieteinheiten sind bereits vermietet:"
            )}\n${units
              .map((unit) => `${unit.data.id} - ${unit.data.displayName}`)
              .join("\n")}`;
          }
          if (errData.code === "RENTAL_AGREEMENT_ID_ALREADY_EXISTS") {
            return `${i18n.t(
              "cb:RentalAgreement.Form.Errors.RENTAL_AGREEMENT_ID_ALREADY_EXISTS",
              "Mietvertrag mit der ID bereits vorhanden"
            )}`;
          }
        }
        return null;
      }
    );
  }
  async submitTenant(values: any, tenant?: Contact) {
    const { helpers, ...formValues } = values;
    return await ContactService.submitContact(
      { contactType: ["TENANT"], ...formValues },
      tenant?._id
    );
  }
  async submitRentalUnit(values: RentalUnitFormValue, rentalUnit?: RentalUnit) {
    const { unitGroup, ...unit } = values;
    const unitCleared = {
      ...unit,
      building: unit.building || "",
    };

    const result = HTTP.post({
      url: `/rental/${rentalUnit.data.objectId}/${rentalUnit._id}/updateRentalUnit`,
      target: "EMPTY",
      bodyParams: unitCleared,
    });
    //  (await SubmitService.submitDataAsync({
    //   type: "asset",
    //   assetType: AssetTypes.Rental.RentalUnit,
    //   data: {
    //     _id: rentalUnit?._id,
    //     data: {
    //       ...values,
    //       ...(rentalUnit
    //         ? {}
    //         : {
    //             // initial value on creation
    //             rentalStatus: "vacant",
    //             status: "active",
    //           }),
    //     },
    //   },
    //   pushToCache: true,
    //   ignorePropChecks: true,
    //   ignoreSubmitValidation: true,
    // })) as RentalUnit;

    DataBus.emit(DataBusSubKeys.STACKING_PLAN_RELOAD, {
      objectId: rentalUnit.data.objectId,
    });
    return result;
  }

  async submitRentalOpos(values: any, rentalOpos: RentalOpos) {
    const result = (await SubmitService.submitDataAsync({
      type: "asset",
      assetType: AssetTypes.Rental.RentalOpos,
      data: {
        _id: rentalOpos?._id,
        data: {
          ...values,
        },
      },
      pushToCache: true,
      ignorePropChecks: true,
      ignoreSubmitValidation: true,
    })) as RentalOpos;

    return result;
  }
  async closeRentalOpos(id: string) {
    const result = (await SubmitService.submitDataAsync({
      type: "asset",
      assetType: AssetTypes.Rental.RentalOpos,
      data: {
        _id: id,
        data: {
          status: "archived",
          closed: moment().toISOString(),
        },
      },
      pushToCache: true,
      ignorePropChecks: true,
      ignoreSubmitValidation: true,
    })) as RentalOpos;

    return result;
  }
  async reopenRentalOpos(id: string) {
    const result = (await SubmitService.submitDataAsync({
      type: "asset",
      assetType: AssetTypes.Rental.RentalOpos,
      data: {
        _id: id,
        data: {
          status: "active",
          closed: null,
        },
      },
      pushToCache: true,
      ignorePropChecks: true,
      ignoreSubmitValidation: true,
    })) as RentalOpos;

    return result;
  }

  async submitRentalUnitPlanData(
    data: SubmitObjectPlanData[],
    reload?: { assetType: string; id: string }
  ) {
    const result = await HTTP.post({
      url: `/rental/changeMultipleRentalUnits`,
      bodyParams: {
        data: data,
      },
      target: "EMPTY",
    });

    if (reload) {
      CacheService.getData({
        assetType: reload.assetType,
        oType: "asset",
        id: reload.id,
        forceReload: true,
        ignoreDelay: true,
      });
    }
  }

  async submitRentalUnits(
    objectId: string,
    rentalUnits: RentalUnitFormValue[]
  ) {
    return await ServiceUtils.toastError(async () => {
      const unitsCleared = rentalUnits.map(({ unitGroup, ...unit }) => ({
        ...unit,
        building: unit.building || "",
      }));
      const result = await HTTP.post({
        url: `/rental/${objectId}/createNewRentalUnits`,
        bodyParams: {
          rentalUnits: unitsCleared,
        },
        target: "EMPTY",
      });

      return result;
    });
  }

  async submitRentalAgreementDeposit(
    rentalAgreementId: string,
    form: DepositPosition[]
  ) {
    return await ServiceUtils.toastError(async () => {
      const result = await HTTP.post({
        url: `/rental/${rentalAgreementId}/updateRentalAgreementDeposit`,
        bodyParams: form,
        target: "EMPTY",
      });
      CacheService.update(result);
    });
  }
  async submitRentalAgreementIndex(
    rentalAgreementId: string,
    form: IndexOption
  ) {
    return await ServiceUtils.toastError(async () => {
      const result = await HTTP.post({
        url: `/rental/${rentalAgreementId}/updateRentalAgreementIndex`,
        bodyParams: form,
        target: "EMPTY",
      });
      CacheService.update(result);
    });
  }
  async submitRentalAgreementAutomatic(
    rentalAgreementId: string,
    form: RentalAgreementAutomatic
  ) {
    return await ServiceUtils.toastError(async () => {
      const result = await HTTP.post({
        url: `/rental/${rentalAgreementId}/updateRentalAgreementAutomatic`,
        bodyParams: form,
        target: "EMPTY",
      });
      CacheService.update(result);
    });
  }
  async submitRentalAgreementOptions(
    rentalAgreementId: string,
    form: AgreementOption
  ) {
    return await ServiceUtils.toastError(async () => {
      const result = await HTTP.post({
        url: `/rental/${rentalAgreementId}/updateRentalAgreementOptions`,
        bodyParams: { option: form },
        target: "EMPTY",
      });
      CacheService.update(result);
    });
  }
  async submitRentalAgreementContact(
    rentalAgreementId: string,
    form: ContactOption
  ) {
    return await ServiceUtils.toastError(async () => {
      const result = await HTTP.post({
        url: `/rental/${rentalAgreementId}/updateRentalAgreementContactOption`,
        bodyParams: form,
        target: "EMPTY",
      });
      CacheService.update(result);
    });
  }

  async removeRentalAgreementIndex(rentalAgreementId: string) {
    return await ServiceUtils.toastError(async () => {
      const result = await HTTP.post({
        url: `/rental/${rentalAgreementId}/removeRentalAgreementIndex`,
        target: "EMPTY",
      });
      CacheService.update(result);
    });
  }
  async removeRentalAgreementDeposit(rentalAgreementId: string) {
    return await ServiceUtils.toastError(async () => {
      const result = await HTTP.post({
        url: `/rental/${rentalAgreementId}/removeRentalAgreementDeposit`,
        target: "EMPTY",
      });
      CacheService.update(result);
    });
  }
  async removeRentalAgreementOptions(rentalAgreementId: string) {
    return await ServiceUtils.toastError(async () => {
      const result = await HTTP.post({
        url: `/rental/${rentalAgreementId}/removeRentalAgreementOptions`,
        target: "EMPTY",
      });
      CacheService.update(result);
    });
  }
  async removeRentalAgreementAutomatic(rentalAgreementId: string) {
    return await ServiceUtils.toastError(async () => {
      const result = await HTTP.post({
        url: `/rental/${rentalAgreementId}/removeRentalAgreementAutomatic`,
        target: "EMPTY",
      });
      CacheService.update(result);
    });
  }
}
const CBRentalService = new CBRentalServiceClass();
export default CBRentalService;
