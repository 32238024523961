import { ObjectKind } from "@/apps/tatar/objectsApp/types/objectKind.interface";
import FormStruct from "@/components/Form/FormStruct/FormStruct";
import FormValidators from "@/components/Form/Validation/FormValidators";
import PDFViewer from "@/components/PDFViewer/PDFViewer";
import i18n from "@/i18n";
import { AssetTypes } from "@/model/AssetTypes";
import EZAssetSelectMultiple from "@/modules/ez-form/form-elements/ez-asset-select/EZAssetSelectMultiple";
import ObjectKindStruct from "@/redux/actions/struct/implemented/ObjectKindStruct";
import FileUtils from "@/utils/FileUtils";
import MQ from "@/utils/MatchQueryUtils";
import classNames from "classnames";
import _ from "lodash";
import { useState } from "react";
import { Field } from "react-final-form";
import { EnrichtedRentalUnit, RentalUnit } from "../../../TenantsInterfaces";
import ObjectStackingPlan from "../../stacking-plan/ObjectStackingPlan";
import {
  RentalUnitTableEntry,
  RentalUnitTableHeader,
} from "../CBRentalAgreementHelper";
import { CBRentalAgreementFormWizardStep1Value } from "./CBRentalAgreementFormWizardStep1UploadContract";
import { CBRentalAgreementFormWizardStep2Value } from "./CBRentalAgreementFormWizardStep2ContractPartners";
import "./CBRentalAgreementFormWizardStep3RentalUnits.scss";

export type CBRentalAgreementFormWizardStep3Value = {
  rentalUnits: {
    assetId: string;
    assetType: string;
  }[];
};
interface CBRentalAgreementFormWizardStep3RentalUnitsProps {
  kind: ObjectKind;
  data: {
    step1: CBRentalAgreementFormWizardStep1Value;
    step2: CBRentalAgreementFormWizardStep2Value;
  };
  onValueChange: (index: number, value: any) => void;
  initialValues?: CBRentalAgreementFormWizardStep3Value;
  onBack: () => void;
  onSuccess: (values: CBRentalAgreementFormWizardStep3Value) => Promise<void>;
}
const CBRentalAgreementFormWizardStep3RentalUnits = (
  props: CBRentalAgreementFormWizardStep3RentalUnitsProps
) => {
  const [fileUrl, setFileUrl] = useState(
    FileUtils.fileToObjectUrl(props.data?.step1?.rentalAgreement?.[0]?.file)
  );

  const type = props.kind.data.type;
  const objectId = props.data.step2.objectId;

  const options = ObjectKindStruct.getRentalUnitAssetSelectionOptions(
    {
      renderValue: (asset: RentalUnit) => {
        return <RentalUnitTableEntry rentalUnit={asset} />;
      },
    },
    props.kind._id
  );
  return (
    <FormStruct
      title={i18n.t(
        "cb:RentalAgreement.Form.Step3.title",
        "Mieteinheiten auswählen"
      )}
      description={i18n.t(
        "cb:RentalAgreement.Form.Step3.description",
        "Wählen Sie die Mieteinheiten aus, welche mit diesem Mietvertrag vermietet werden."
      )}
      onSubmit={async (values) => {
        props.onSuccess(values);
      }}
      submitText={i18n.t("Global.Buttons.continue")}
      onAbort={(formProps) => {
        props.onValueChange(2, formProps.values);
        props.onBack();
      }}
      initialValues={props.initialValues}
      cancelText={i18n.t("Global.Buttons.back")}
      className={classNames(
        `cb-rental-agreement-form-wizard-step3-rental-units`
      )}
      renderRight={(form) => (
        <div
          className={`pdf-viewer`}
          style={{
            width: "40vw",
            maxWidth: 1000,
            padding: 10,
          }}
        >
          <PDFViewer
            border
            filename={props.data.step1?.rentalAgreement?.[0]?.file?.name}
            height={"80vh"}
            url={fileUrl}
          />
        </div>
      )}
      render={(form) => (
        <>
          {!objectId && (
            <div className={`__empty`}>
              {i18n.t(
                "cb:RentalAgreement.Form.Sections.NoObjectsSelected",
                "Kein Objekt ausgewählt"
              )}
            </div>
          )}

          {objectId && (
            <Field
              name="rentalUnits"
              validate={FormValidators.compose(
                FormValidators.required(),
                FormValidators.min(1)
              )}
            >
              {({ input, meta }) => (
                <>
                  <div className={`stacking-plan`}>
                    <ObjectStackingPlan
                      objectId={objectId}
                      selectMode={"multiple"}
                      onSelect={(units: EnrichtedRentalUnit[]) => {
                        if (
                          !_.isEqual(
                            (input.value || [])?.map((e) => e.assetId),
                            units.map((e) => e._id)
                          )
                        ) {
                          input.onChange(
                            units.map((e) => ({
                              assetId: e._id,
                              assetType: AssetTypes.Rental.RentalUnit,
                            }))
                          );
                        }
                      }}
                      selected={(input.value || []).map((e) => e.assetId)}
                    />
                  </div>
                  <RentalUnitTableHeader />
                  {(input.value || []).length === 0 && (
                    <div className={`__empty`}>
                      {i18n.t(
                        "cb:RentalAgreement.Form.Fields.noRentalUnits",

                        "Keine Mieteinheiten ausgewählt"
                      )}
                    </div>
                  )}
                  <EZAssetSelectMultiple
                    disabled={!objectId || !type}
                    {...input}
                    initialAssetType={options[0].id}
                    block
                    identifier="cb-rental-agreement-rental-unit-select"
                    addButtonText={i18n.t(
                      "cb:RentalAgreement.Form.Fields.addRentalUnit",
                      "Mieteinheit hinzufügen"
                    )}
                    appearance="clear"
                    validation={
                      meta.error && meta.touched
                        ? {
                            level: "error",
                            message: meta.error,
                          }
                        : undefined
                    }
                    additionalMatchQuery={MQ.combineSpread(
                      "and",
                      {
                        type: "op",
                        op: "eq",
                        name: "data.type",
                        value: type,
                      },
                      objectId
                        ? {
                            type: "op",
                            op: "eq",
                            name: "data.objectId",
                            value: objectId,
                          }
                        : null
                    )}
                    assetTypes={options}
                  />
                </>
              )}
            </Field>
          )}
        </>
      )}
    />
  );
};

export default CBRentalAgreementFormWizardStep3RentalUnits;
